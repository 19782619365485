import React, { useState,useEffect } from "react";
import Fuse from "fuse.js";
import "./search.scss";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { dynamicAssetTitle } from "../../util/Helper.js";
//import AuthContext from "../../components/Auth/Auth-context";
import axios from "axios";
import config from "../../config.js";
import {getCountryCode} from "../../util/Helper.js";

import qs from "qs";

const env = process.env.REACT_APP_ENVIRONMENT;
let ga_env = env === "PROD" ? "Production" : "Testing";
window.dataLayer = window.dataLayer || [];

const InternalSearch = (props) => {
  let { RoleSettings, countryLocale } = { ...props };
  const { t } = useTranslation("common");
  const products = useSelector(state => state.product.productsList);
  const assets = useSelector(state => state.asset.assetsList);
  const countrySettings = useSelector(state => state.country.countryData);
  const history = useHistory();
  const [searchKey, setSearchKey] = useState("");
  const [suggestion, setSuggestion] = useState([]);
  const [fusePattern, setFusePattern] = useState("");
  const [viewPermissionData, setviewPermissionData] = useState([])
  const suggestionWrapper = React.createRef();
  const normalValueShow =(item, particularAttribute, countryLocale)=>{
    return(
      typeof item?.[particularAttribute] === "string"
      ? item?.[particularAttribute]  : countryLocale &&  item?.[particularAttribute]?.[countryLocale] ?
      item?.[particularAttribute]?.[countryLocale]  : item?.[particularAttribute]?.['en-ZZ']
    )
  }
  const productOptions =
    countrySettings.countryCode === "NL"
      ? {
          includeMatches: true,
          minMatchCharLength: 3,
          keys: [


            `brand.${countryLocale}`,
            `unitupc`,
            `ean.${countryLocale}`,
            `ptitle.${countryLocale}`,
            `ecomproddesc.${countryLocale}`,
            "keyword",
            "asset_info_print_keywords"
          ],
          threshold: 0.3
        }
      : {
          includeMatches: true,
          minMatchCharLength: 3,
          keys: [
            `brand.${countryLocale}`,
            `unitupc`,
            `pharmacentralnum.${countryLocale}`,
            `ptitle.${countryLocale}`,
            `ecomproddesc.${countryLocale}`,
            "keyword",
            "asset_info_print_keywords"
          ],
          threshold: 0.3
        };

  const assetOptions =
    countrySettings.countryCode === "NL"
      ? {
          includeMatches: true,
          minMatchCharLength: 3,
          keys: [
            "brand",
            "asset_type",
            "file_type",
            "title",
            "description",
            "ean",
            "keyword",
            "assetId",
            "dynamic_title"
          ],
          threshold: 0.3
        }
      : {
          includeMatches: true,
          minMatchCharLength: 3,
          keys: [
            "brand",
            "asset_type",
            "file_type",
            "title",
            "description",
            "pharmacentralnum",
            "keyword",
            "assetId",
            "dynamic_title"
          ],
          threshold: 0.3
        };

  const handleClickOutside = event => {
    if (
      suggestionWrapper.current &&
      !suggestionWrapper.current.contains(event.target)
    ) {
      setFusePattern("");
    }
  };
  const [gigyaRole, setGigyaRole] = useState();
 // const AuthCtx = useContext(AuthContext);
  //const userId = AuthCtx.giveToken();
  const userId= localStorage.email
  const postGigyaID = async () => {
      const response = await axios({
        method: "get",
        url: `${config.baseApi}dc/exports/getrolepermission/${userId}`,
        data: {
          "useAdministration": {
            "Gigya_ID": userId,
          }
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      })
      setGigyaRole(response.data.data?.roleName);
      setviewPermissionData(response.data.data?.viewPermission)
    }
    useEffect(() => {
      postGigyaID();
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem('previousLanguage'), localStorage.getItem('email'),  localStorage.getItem("user_role")]); 
  document.addEventListener("mousedown", handleClickOutside);

  products.forEach(product => {
    if (Object.keys(product).includes("asset_info")) {
      let keySearch = product.asset_info;
      if (Object.keys(keySearch).includes("keywords")) {
        let productKeys = Array.isArray(keySearch.keywords)
          ? keySearch.keywords[0].split(",")
          : keySearch.keywords.split(",");
        productKeys.forEach(function(productKey) {
          productKey = productKey.trim();
        });
        product.keyword = productKeys;
      }
    }
    if (Object.keys(product).includes("asset_info_print")) {
      let keySearch = product.asset_info_print;
      if (Object.keys(keySearch).includes("keywords")) {
        let productKeys = Array.isArray(keySearch.keywords)
          ? keySearch.keywords[0].split(",")
          : keySearch.keywords.split(",");
        productKeys.forEach(function(productKey) {
          productKey = productKey.trim();
        });
        product.asset_info_print_keywords = productKeys;
      }
    }
  });

  assets.forEach(asset => {
    if (Object.keys(asset).includes("keywords")) {
      let assetKeys = Array.isArray(asset.keywords)
        ? asset.keywords[0].split(",")
        : asset.keywords.split(",");
      assetKeys.forEach(function(assetKey) {
        assetKey = assetKey.trim();
      });
      asset.keyword = assetKeys;
    }
    asset.dynamic_title = dynamicAssetTitle(asset, t, products);
  });

  const handleChange = event => {
    const searchValue = event.target.value;
    setFusePattern("");
    setSearchKey(searchValue);
    if (searchValue.length > 2) {
      setFusePattern(searchValue);
      const productFuse = new Fuse(products, productOptions);
      const assetFuse = new Fuse(assets, assetOptions);
      const productResult = productFuse.search(searchValue);
      const assetResult = assetFuse.search(searchValue);
      const productArray = [];
      let productArrayFiltered = [];
      const assetArray = [];
      productResult.forEach(function(productItem) {
        productArray.push({
          key: normalValueShow(productItem.item,"id",countryLocale),
          value: productItem.item.ptitle ? normalValueShow(productItem.item,"ptitle",countryLocale) : "",
          category: "Product",
          plcstatus: productItem.item?.plcstatus? productItem.item?.plcstatus?.['en-ZZ'] : "",
          brand: productItem.item.brand? normalValueShow(productItem.item,"brand",countryLocale) : "",
          hideShow:productItem.item?.hideShowStatus ? productItem.item?.hideShowStatus?.[0]?.currentStatus : "",
        });
        (gigyaRole === "Global Admin" || gigyaRole === "Regional Admin" || gigyaRole === "Country Admin")
        ? productArrayFiltered  = 
        productArray && productArray?.filter(item => 
                  typeof item?.plcstatus === "string" 
                    ? item?.plcstatus?.toLowerCase().includes("ready for publication") 
                    : Array.isArray(item?.plcstatus?.['en-ZZ']) 
                      ? item?.plcstatus?.['en-ZZ'].some(status => 
                          JSON.stringify(status)?.toLowerCase().includes("ready for publication")
                        ) 
                      : JSON.stringify(item?.plcstatus?.['en-ZZ'])?.toLowerCase().includes("ready for publication")
                )
              : productArrayFiltered  = 
              productArray && productArray?.filter(item => 
                typeof item?.plcstatus === "string" 
                  ? item?.plcstatus?.toLowerCase().includes("ready for publication") 
                  : Array.isArray(item?.plcstatus?.['en-ZZ']) 
                    ? item?.plcstatus?.['en-ZZ'].some(status => 
                        JSON.stringify(status)?.toLowerCase().includes("ready for publication")
                      ) 
                    : JSON.stringify(item?.plcstatus?.['en-ZZ'])?.toLowerCase().includes("ready for publication") && 
                      (item?.hideShowStatus?.[0]?.currentStatus === "show" || !item?.hideShowStatus)
              )
       // productArrayFiltered = productArray.filter(product => !Object.keys(product).includes("upc") && product?.plcstatus?.['en-ZZ']?.toLowerCase().includes("ready for publication") )
      });
      assetResult.forEach(function(assetItem) {
        assetArray.push({
          key: assetItem.item.id,
          value: assetItem.item?.title
            ? normalValueShow(assetItem.item,"title",countryLocale) 
            : "",
          category: assetItem.item.asset_type
            ? assetItem.item.asset_type.map(type => type.dcAssetType)[0]
            : "",
          brand: assetItem.item.brand ? normalValueShow(assetItem.item,"brand",countryLocale): "",
          hideShow:assetItem.item?.hideShowStatus ? assetItem.item?.hideShowStatus?.[0]?.currentStatus : "",
        });
      });
      const result = productArrayFiltered.concat(assetArray)
      //   gigyaRole === "Admin"
      //   ? assetArray.filter(asset => !Object.keys(asset).includes("upc"))
      //   : assetArray.filter(asset => !Object.keys(asset).includes("upc") && (asset?.hideShow ==="show"))        
      //   // assetArray.filter(asset => !Object.keys(asset).includes("upc"))
      // );
      setSuggestion(result);
    }
  };

  const onKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      setFusePattern("");
      const paramObj = { searchKey: event.target.value };
      let paramString = qs.stringify(paramObj);
      window.dataLayer.push({
        event: "gaGenericEvent",
        eventCategory: "Search Term(local)",
        eventAction: event.target.value,
        eventLabel: "searchKey",
        eventValue: event.target.value,
        environment: ga_env
      });
      history.push(`/search-page/?${paramString}`);
    }
  };

  const handleClick = event => {
    event.preventDefault();
    setFusePattern("");
    const paramObj = { searchKey: searchKey };
    let paramString = qs.stringify(paramObj);
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Search Term(local)",
      eventAction: searchKey,
      eventLabel: "searchKey",
      eventValue: searchKey,
      environment: ga_env
    });
    history.push(`/search-page/?${paramString}`);
  };

  function handleUserClick(item) {
    setFusePattern("");
    setSearchKey(item.value);
    if (item.category === "Product") {
      /*  const paramObj = {};
      Object.keys(item).includes("brand")
        ? (paramObj["brandName"] = item.brand.trim())
        : (paramObj["brandName"] = "");
      paramObj["id"] = item.key;
      paramObj["title"] = item.value;
      let paramString = qs.stringify(paramObj); */
      history.push({
        pathname: `/product-detail-page/${item.key}`,
        state: {
          queryParams: { brand: [ normalValueShow(item,"brand",countryLocale)?.trim()] },
          id: item.key,
          title: item.value
        }
      });
    } else {
      /* const paramObj = {};
      Object.keys(item).includes("brand")
        ? (paramObj["brandName"] = item.brand.trim())
        : (paramObj["brandName"] = "");
      paramObj["id"] = item.key;
      paramObj["title"] = item.value;
      let paramString = qs.stringify(paramObj); */
      history.push({
        pathname: `/asset-detail-page/${item.key}`,
        state: {
          queryParams: { brand: [normalValueShow(item,"brand",countryLocale)?.trim()] },
          id: item.key,
          title: item.value
        }
      });
    }
  }

  const getItems = suggestion.map((item, index) => {
    return (
      <li
        value={item.value}
        key={item.key}
        onClick={handleUserClick.bind(this, item)}
      >
        {item.value} <span className="item-desc">({t(item.category)})</span>
      </li>
    );
  });

  const GetDropDown = () => {
    if (suggestion.length) {
      return (
        <div className="suggestion_wrapper" ref={suggestionWrapper}>
          <ul className="suggestions">{getItems}</ul>
        </div>
      );
    } else {
      return (
        <div className="no-suggestions">
          <em>No suggestions available.</em>
        </div>
      );
    }
  };

  return (
    ((RoleSettings &&  RoleSettings['Product Search']) || (viewPermissionData && viewPermissionData['Product Search']) === true)? 
    <div className="searchBox">
      <Form>
        <Form.Row>
          <div className="search-input">
            <Form.Control
              placeholder={t("SearchPage.placeholder")}
              value={searchKey}
              onChange={handleChange}
              onKeyDown={onKeyDown}
            />
          </div>
          <div className="search-button">
            <Button variant="primary" type="submit" onClick={handleClick}>
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </div>
        </Form.Row>
      </Form>
      {fusePattern && <GetDropDown />}
    </div>
    : ""
  );
};
const mapStateToProps = state => {
  return {
    brandList: state.filter.brand,
    assetType: state.filter.assets,
    countrySettings: state.country.countryData,
    productsList: state.product.productsList,
    assetsList: state.asset.assetsList,
    RoleSettings: state.role.countryData,
    countryLocale: state.locale.countryLocale
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    null
  )
)(InternalSearch);
