import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef
} from "react";
import AuthContext from "../Auth/Auth-context";
import "./login.scss";
import axios from "axios";
import logo from "../../sass/assets/loginPageBackground.jpg";
import downloadCenterLogo from "../../sass/assets/downloadCenterLogo.png";
// import bayerLogo from "../../sass/assets/bayerLogo.png";
import {legalTest} from '../../util/Helper'
import bayerLogoRGB from "../../sass/assets/bayerLogo_RGB.png";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import config from "../../config.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { InputLabel,MenuItem,Select,TextField } from "@mui/material"
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import { Button,  Modal } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';
const env = process.env.REACT_APP_ENVIRONMENT;
let ga_env = env === "PROD" ? "PROD" : "QA";

const Login = props => {
  const isMediumScreen = useMediaQuery({ query: "(max-width: 991px)" });
  const { countrySettings } = { ...props };
  const { t } = useTranslation("common");
  const isLargeScreen = useMediaQuery({ query: "(min-width: 992px)" });
  const history = useHistory();
  const gigya = window.gigya;
  const authCtx = useContext(AuthContext);
  const [errorMsg, setErrorMsg] = useState("");
  const [visible, setVisible] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [regFirstName, setRegFirstName] = useState("")
  const [regLastName, setRegLastName] = useState("")
  const [regEmail, setRegEmail] = useState("")
  const [gender, setGender] = useState("");
  const [role, setRole] = useState("")
  const [checked, setChecked] = useState(false);
  const [hiddenInfo, setHiddenInfo] = useState("");
  const [hiddenName, setHiddenName] = useState("");
  const [hiddenLocation, setHiddenLocation] = useState("");
  const [countryFilter, setcountryFilter] = useState("");
  const [lang, setLang] = useState("");
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  var noOccurence = useRef(true);
  const containerRef =useRef(null);
  const bayerUrl = "https://www.bayer.com";

  const Successnotify = () => toast.success('Your request was sent, you will be informed by the download center team after the account was created. It may take some time. Please check your mailbox after some time.', {
    position: "bottom-center",
    style: {
      // border: '1px solid #713200',
      padding: '16px',
      color: 'white',
      background: '#108000'
    },
    iconTheme: {
      primary: 'white',
      secondary: 'black',
    },
    }
  )
  const cancelnotify = () => toast.error('No mail has been sent', {
    position: "bottom-center",
    style: {
      // border: '1px solid #713200',
      padding: '16px',
      color: 'white',
      background: 'rgb(255, 49, 98)'
    },
    iconTheme: {
      primary: 'white',
      secondary: 'red',
    },
  }
  )
  const loginRedirector = useCallback(
    e => {
      //console.log("####LOGIN RESPONSE ====>", e);
      const token = e.response.UID;
      const idToken = e.response.idToken;
      const provider = e.response.loginProvider;
      const firstName = e.response.profile.firstName;
      const lastName = e.response.profile.lastName;
      const email = e.response.profile.email;
      const userProfile = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        loginProvider: provider
      };
      authCtx.login(token, provider, userProfile, idToken);
      history.replace("/home");
    },
    [authCtx, history]
  );

  const firstLoginHandler = useCallback(
    eventObj => {
      var application = window.location.hostname;
      if (application === "localhost") {
        return;
      } else {
        var date = new Date();
        var apps = eventObj.data.bc_accessApplications;
        var json_obj;
        var timestamp = date.toISOString();
        if (!apps) {
          json_obj = {
            hostname: application,
            firstLogin: timestamp,
            lastLogin: timestamp
          };
          apps = [JSON.stringify(json_obj)];
        } else {
          var isNewApp = true;
          var appIndex;
          for (var i = 0; i < apps.length; i++) {
            var host = JSON.parse(apps[i]).hostname;
            if (host === application) {
              isNewApp = false;
              appIndex = i;
              break;
            }
          }
          if (isNewApp) {
            json_obj = {
              hostname: application,
              firstLogin: timestamp,
              lastLogin: timestamp
            };
            apps.push(JSON.stringify(json_obj));
          } else {
            json_obj = JSON.parse(apps[appIndex]);
            json_obj.lastLogin = timestamp;
            apps[appIndex] = JSON.stringify(json_obj);
          }
        }
        gigya.accounts.setAccountInfo({
          data: { bc_accessApplications: apps }
        });
      }
    },
    [gigya.accounts]
  );

  const setError = () => {
    if (noOccurence.current) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
    }
  };
  const getApplicationValue = (searchObject, searchKey) => {
    var appValue =
      searchObject[
        Object.keys(searchObject).find(
          key => key.toLowerCase() === searchKey.toLowerCase()
        )
      ];
    return appValue;
  };
  const findApplication = useCallback(
    (e, registeredApps, setErrorcallBack) => {
      for (var i = 0; i < registeredApps.length; i++) {
        var appKey = "Application key";
        var targetObject = registeredApps[i];
        const applicationName = getApplicationValue(targetObject, appKey);
        const Substring = "Download Center";
        if (applicationName.indexOf(Substring) >= 0) {
          loginRedirector(e);
          noOccurence.current = false;
          break;
        }
      }
      setErrorcallBack();
    },
    [loginRedirector]
  );

  function checkLogin(res) {
    if (res.loginProvider === "doccheck") {
      return true;
    }
    let email = res.profile.email;
    if (
      email.slice(email.lastIndexOf("@") + 1, email.length) === "bayer.com" &&
      res.loginProvider === config.loginProvider
    ) {
      return true;
    }
    return false;
  }
  const gigyaScreenSet = useCallback(() => {
    gigya.accounts.showScreenSet({
      screenSet: `${countrySettings?.gigya?.screenSet}`,
      startScreen: `${countrySettings?.gigya?.startScreen}`,
      containerID: "gigyaScreenSetContainer",
      lang: t("lang_code"),
      onAfterSubmit: function(event) {
        firstLoginHandler(event);
        const errorCode = JSON.parse(event.response.errorCode);
        if (errorCode === 0) {
          if (checkLogin(event.response)) {
            loginRedirector(event);
          } else {
            if (event.data.bc_isMemberOf) {
              const applications = event.data.bc_isMemberOf;
              if (typeof applications === "string") {
                let application = JSON.parse(applications);
                const applicationName = application["Application Key"];
                const Substring = "Download Center";
                if (applicationName.indexOf(Substring) >= 0) {
                  loginRedirector(event);
                } else {
                  setErrorMsg(true);
                }
              } else if (Array.isArray(applications)) {
                let application = applications.map(info => JSON.parse(info));
                findApplication(event, application, setError);
              }
            } else {
              if (event.form === "gigya-login-form") {
                setErrorMsg(true);
              }
            }
          }
        }
      },
      onAfterScreenLoad: function() {
        let gigyaContainer = document.querySelector(".gigyaScreenSetContainer");
        let heightBox = gigyaContainer.offsetHeight;
        let parentDivHeight = parseInt(heightBox, 10) + 200;
        let parentDivHeightStyle = "height:" + parentDivHeight + "px";
        document
          .querySelector(".loginPage")
          .setAttribute("style", parentDivHeightStyle);
        document
          .querySelector(".login-background-image")
          .setAttribute("style", parentDivHeightStyle);
        document
          .querySelector(".over-lay-right")
          .setAttribute("style", parentDivHeightStyle);
      }
    });
  }, [
    countrySettings,
    findApplication,
    firstLoginHandler,
    gigya.accounts,
    loginRedirector,
    t
  ]);


  useEffect(() => {
    if(countrySettings?.gigya?.screenSet) {
      gigyaScreenSet();
    }
   
    langTranslation(countrySettings)
    
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gigyaScreenSet, countrySettings]);
  useEffect(() => {
    // var scrollComponent = this;
    const appContainer = document.querySelector(".App");
    appContainer.addEventListener("scroll", function(e) {
      toggleVisibility();
  });
 
}, [])
useEffect(() => {
  legalTest()
}, [])


const toggleVisibility=()=>{
  if (containerRef.current) {
    var scrollTop = containerRef.current.getBoundingClientRect().top;
    if (scrollTop < -50) {
      setVisible(true)
      // this.setState({
      //   is_visible: true
      // });
    } else {
      setVisible(false)
      // this.setState({
      //   is_visible: false
      // });
    }
  }
}
const scrollToTop=() =>{
  const appContainer = document.querySelector(".App");
  appContainer.scrollTo({
    top: 0,
    behavior: "smooth"
  });
}



const langTranslation=(userCountry)=>{
  // console.log("user",userCountry?.countryCode?.toLowerCase())
setLang(userCountry?.locale)
    //   if(userCountry==="de"){
    //     setLang("de-DE")
    // }
    // else if(userCountry==="fr"){
    //   setLang("fr-BE")
    // }
    // else if(userCountry==="nl"){
    //   setLang("nl-NL")
    // }
    // else if(userCountry==="us"){
    //   setLang("en-US")
    // }
    // else if(userCountry==="be"){
    //   setLang("nl-NL")
    // }
    // else{
    //   setLang("en-US")
    // }
}

function getTranslation(lang, label) {
  const translation = countrySettings?.accountRequestPageTranslations?.find(item =>
      item?.location === "Account Request Page" &&
      item?.labels === label &&
      item[lang]
  );

  if (translation) {
      return translation[lang];
  } else {
      // If translation for specific country code not found, fall back to default value
      const defaultTranslation = countrySettings?.accountRequestPageTranslations?.find(item =>
          item?.location === "Account Request Page" &&
          item?.labels === label &&
          item?.defaultValue
      );

      if (defaultTranslation) {
          return defaultTranslation.defaultValue;
      } else {
          // If no default value is provided, return an empty string or handle it as needed
          return "";
      }
  }
}


const handleCloseModal = () => {
  setShowModalEdit(false);
  setRegFirstName("")
  setRegLastName("")
  setRegEmail("")
  setGender("");
  setRole("");
  setChecked(false);
  setHiddenInfo("")
  setHiddenName("")
  setHiddenLocation("")
  setcountryFilter("")
  setSubmitButtonClicked(false)
};

const handleCloseModalCross = () => {
  cancelnotify()
  setShowModalEdit(false);
  setRegFirstName("")
  setRegLastName("")
  setRegEmail("")
  setGender("");
  setRole("");
  setChecked(false);
  setHiddenInfo("")
  setHiddenName("")
  setHiddenLocation("")
  setcountryFilter("")
  setSubmitButtonClicked(false)
};
const OpenModal=()=>{
  setShowModalEdit(true)  
}

const handleRegFirstName = (e) => {
  setRegFirstName(e.target.value)
};

const handleRegLastName = (e) => {
  setRegLastName(e.target.value)
};

const handleRegEmail = (e) => {
  setRegEmail(e.target.value)
};

const handleGenderChange = (event) => {
  setGender(event.target.value);
};

const handleRoleChange = (event) => {
  setRole(event.target.value);
};

const handleCheckChange = (event) => {
  setChecked(event.target.checked);
};

const handleHiddenInfo=(event)=>{
setHiddenInfo(event.target.value)
}
const handleHiddenName=(event)=>{
 setHiddenName(event.target.value)
}
const handleHiddenLocation=(event)=>{
  setHiddenLocation(event.target.value)
}

const handleRegSubmit= async()=>{
  Successnotify()
  const response = await axios({
    method: "POST",
    url: `${config.favApi}dc/config/register`,
    data:{
      gender: gender,
      firstName: regFirstName,
      lastName: regLastName,
      email: regEmail,
      information: hiddenInfo,
      nameRelatedToRole: hiddenName,
      location: hiddenLocation,
      role: role,
      countrySelectedByUser:countryFilter,
      countryCodeFromBrowser: countrySettings?.countryCode?.toLowerCase(),
      environment: ga_env
  },
  })
  handleCloseModal()
  return response
}

const handlecountryChange = (evt) => {
  setcountryFilter(evt.target.value);
};
const disableButton =() =>{
  setSubmitButtonClicked(true)
}
const is_visible = visible;
  return (
    <React.Fragment>
      <div className="loginPage"  ref={containerRef}>
      <img className="login-background-image" src={logo} alt="logo" />
        <div className="overlay-left">
        </div>
        <div className="logincontainer">
          {(isMediumScreen|| isLargeScreen) && (
            <div className="bayer-logo-wrapper">
              
              <a href={bayerUrl}>
                <img className="bayerLogo" src={bayerLogoRGB} alt="logo" />
              </a>
              <img
                className="downloadCenterLogo"
                src={downloadCenterLogo}
                alt="logo"
              />
            </div>
          )}
          <div className="over-lay-right"></div>
          {errorMsg && (
            <div className="loginError">Sorry, Please request for accesss!</div>
          )}
          <div
            className="gigyaScreenSetContainer"
            id="gigyaScreenSetContainer"
          ></div>
          
            <div className="register-link" onClick={() => OpenModal()} >
              <span>
              {getTranslation(lang, "noAccountText")}
              </span>
            </div>
        </div>
        {is_visible && (
                  <button id="upBtn" onClick={() => scrollToTop()}>
                    <FontAwesomeIcon className="up-icon" icon={faAngleUp} />
                    Top
                  </button>
                )}
      </div>


      <Modal show={showModalEdit}  onHide={handleCloseModalCross} aria-labelledby="contained-modal-title-vcenter" centered className="modalColor">
      <Modal.Header style={{color: "black", }}>
            <Modal.Title>{getTranslation(lang, "heading")}</Modal.Title>
            <button type="button" class="btn-close" aria-label="Close" onClick={handleCloseModalCross}></button>
          </Modal.Header>
  <FormControl >
  
  <div style={{position:"relative", display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
  <TextField id="firstName" label={getTranslation(lang, "firstName")} required value={regFirstName} variant="outlined" onChange={handleRegFirstName} />
  <TextField id="lastName" label={getTranslation(lang, "lastName")}  required value={regLastName} variant="outlined" onChange={handleRegLastName}/>
  </div> 
  <div >
  <TextField id="email" label={getTranslation(lang, "emailId")}  required value={regEmail} variant="outlined" onChange={handleRegEmail} style={{marginTop: "2%",width:"100%"}} />
  <FormControl variant="outlined" style={{ marginTop: "2%", width: "100%" }} required>
    <InputLabel id="demo-simple-select-label">{getTranslation(lang, "gender")}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={gender}
      label={getTranslation(lang, "gender")}
      onChange={handleGenderChange}
    >
      <MenuItem value="Female">{getTranslation(lang, "female")}</MenuItem>
      <MenuItem value="Male">{getTranslation(lang, "male")}</MenuItem>
      <MenuItem value="I wish not to reveal">{getTranslation(lang, "others")}</MenuItem>
    </Select>
  </FormControl>
  <FormControl variant="outlined" style={{ marginTop: "2%", width: "100%" }} required>
    <InputLabel id="demo-simple-select-label">{getTranslation(lang, "country")}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={countryFilter}
      label={getTranslation(lang, "country")}
      onChange={handlecountryChange}
      defaultValue={countryFilter}
      displayEmpty          
    >
   {countrySettings?.availableCountries?.filter((item) => item.countryCode !== "GBL").map((item, index) => {
    return (
      <MenuItem  key={index} value={item?.countryCode}>{item?.region}</MenuItem>
  );
})}
    </Select>
  </FormControl>
  {(countryFilter)? 
   <FormControl variant="outlined" style={{ marginTop: "2%", width: "100%" }} required>
   <InputLabel id="demo-simple-select-label">{getTranslation(lang, "selectRole")}</InputLabel>
   <Select
     labelId="demo-simple-select-label"
     id="demo-simple-select"
     value={role}
     label={getTranslation(lang, "selectRole")}
     onChange={handleRoleChange}
   >
 {countrySettings?.availableCountries?.map((item) => (
  item?.availbleRolesForTheCountry.map((role, index) => {
    return (
      role.countryCode === countryFilter && role.roleNameEditable !== "Country Admin" && role.roleNameEditable !== "Global Admin" && role.roleNameEditable !== "Regional Admin" ? (
      <MenuItem key={index} value={role.roleNameEditable}>{role.roleNameEditable}</MenuItem>
    ) : null
  )
})
))}
   </Select>
 </FormControl>
  :""}
    { regFirstName && regLastName && regEmail && gender && role  ? 
          <>
          <TextField required label={role + " "+  getTranslation(lang, "roleInfo")} value={hiddenInfo}  variant="outlined" onChange={(e)=>handleHiddenInfo(e)} style={{marginTop: "2%",width:"100%"}} />
          <TextField required label={role + " "+ getTranslation(lang, "roleName")} value={hiddenName} variant="outlined" onChange={(e)=>handleHiddenName(e)} style={{marginTop: "2%",width:"100%"}} />
          <TextField required label={role + " "+ getTranslation(lang, "roleLoc")} value={hiddenLocation} variant="outlined" onChange={(e)=>handleHiddenLocation(e)} style={{marginTop: "2%",width:"100%"}} />
          </> : 
           ""
            }
  
  

        <FormGroup>
        <FormControl >
            <div style={{display:"flex", textAlign:"left", marginTop:"2%", cursor:"pointer", color:"#e76468"}}>
            {getTranslation(lang, "mandatory")}
            </div>
          </FormControl>
          <FormControl style={{marginTop:"2%"}}>
            <FormControlLabel required 
              control={
                <Checkbox checked={checked} onChange={handleCheckChange} required />
              }
              label= {<span class="gigya-label-text gigya-checkbox-text" data-screenset-element-id-publish="false" data-screenset-roles="instance">{getTranslation(lang, "termsFirst")} <a class="gigya-terms-of-use" href={window.location.origin + "/conditions"} target="_blank" rel="noreferrer">{getTranslation(lang, "termsLink")}</a>.<span style={{ color: 'red' }}>*</span> </span>}
            />
          </FormControl>
          { !submitButtonClicked ?
          <FormControl style={{marginTop:"2%"}}>
            <Button className="sumbmitButton" 
            variant="contained" color="secondary"
            disabled={regFirstName && regLastName && regEmail && gender && role && checked && hiddenInfo && hiddenName && hiddenLocation ? false : true}
             type="submit"
            onClick={()=>{handleRegSubmit(); disableButton()}}>{getTranslation(lang, "submit")}</Button>
          </FormControl>
          :
          <FormControl style={{marginTop:"2%"}}>
            <Button className="sumbmitButton" 
            variant="contained" color="secondary"
            disabled={true}
             type="submit"
            onClick={()=>{handleRegSubmit(); disableButton()}}>{getTranslation(lang, "submit")}</Button>
          </FormControl>
          }
          


        </FormGroup>
        <FormGroup>
          
 

          <FormControl>
            <div className="accountHover" style={{display:"flex", justifyContent:"center", marginTop:"3%", cursor:"pointer", color:"#0d6efd", fontWeight:"700"}} onClick={()=>handleCloseModal()}>
            {getTranslation(lang, "haveAcc")}
            </div>
          </FormControl>
        </FormGroup>
  </div>
  </FormControl>
  <Modal.Footer className="modalButton">
<div></div>
  </Modal.Footer>
      </Modal>
      <Toaster />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  countrySettings: state.country.countryData
});
export default connect(mapStateToProps)(Login);
