import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import config from "../../config.js";
import "./ProfilePage.scss";
import Accordion from "../../components/Accordion/Accordion.jsx";
import {

  fetchCountrySettingsSuccess,
  updateRenderCountryCode,
  fetchProductsSuccess,
  fetchAssetsSuccess,
  setFilterBrandName,
  setFilterAssetTypes,
  setFilterFileTypes,
} from "../../redux";

import _ from "lodash";
import store from "../../redux/store";
  // eslint-disable-next-line
  import { getCountryCode } from "../../util/Helper.js";
const ProfilePage = props => {
  const gigya = window.gigya;
  const { title, countrySettings, countryCode,RoleSettings } = { ...props };
  const [loginProvider, setLoginProvider] = useState(null);
  const userId = localStorage.email;
  const { t, i18n } = useTranslation("common");
  const [languageSet, setLanguages] = useState(null);
  const [countriesSet, setCountries] = useState(null);
  const [userRoleId, setUserRoleId] = useState(null);
  const [userEmailId, setUserEmailId] = useState(null);
  const [screenLoaded, setScreenLoaded] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(getCountryCode().toLowerCase());
  const [selectedLang, setSelectedLang] = useState(localStorage.getItem("LegalTextLanguageFilter"));

  useEffect(() => {
    let user_token = localStorage.getItem("token");
    gigya.accounts.getAccountInfo(user_token, {
      callback: getAccountInfoResp
    });
  }, [gigya]);
  useEffect(() => {
    document.title = title;
  }, [title]);

  const getAccountInfoResp = resp => {
    if (resp.errorCode === 0) {
      let loginProvider = resp.loginProvider;
      setLoginProvider(loginProvider);
    } else {
      console.log("Alert" + resp.errorMessage);
    }
  };
  useEffect(() => {
    const profilePage = () => {
      gigya.accounts.showScreenSet({
        screenSet: "bayer-ProfileUpdate",
        startScreen: "bayer-update-profile-screen",
        containerID: "profilePageScreen",
        lang: t("lang_code"),
        onHide: handleResult,
        onAfterScreenLoad: function(event) {
          let email = event.profile.email;
          let user_profile = JSON.parse(localStorage.getItem("userProfile"));
          if (
            email.slice(email.lastIndexOf("@") + 1, email.length) ===
              "bayer.com" &&
            loginProvider === "saml-azure_dev"
          ) {
            setScreenLoaded(true);
          } else if (Object.keys(user_profile).includes("loginProvider")) {
            if (
              email.slice(email.lastIndexOf("@") + 1, email.length) ===
                "bayer.com" &&
              user_profile.loginProvider === "saml-azure_dev"
            ) {
              setScreenLoaded(true);
            }
          }
        }
      });
    };
    profilePage();
  }, [t, gigya, loginProvider]);
  useEffect(() => {
    if (screenLoaded) {
      if (document.querySelectorAll("#profilePageScreen_content").length > 0) {
        document
          .querySelector("#profilePageScreen_content")
          .classList.add("hide_reset_pwd");
      }
    }
  }, [screenLoaded]);
  useEffect(() => {
    let country_data
    let user_country;
    const countrySettings = async () => {
      const countrySettingsUrl = `${config.baseApi}dc/config/country`;
      let country_settings = await axios({
        method: "get",
        url: `/${countryCode ? countryCode : getCountryCode()}`,
        baseURL: countrySettingsUrl,
        headers: { Authorization: "Bearer" }
      });
     country_data = country_settings.data.data;
      store.dispatch(fetchCountrySettingsSuccess(country_data)); 
      setLanguages(country_data?.languageDropdownData.filter(country =>Object.keys(country_data?.accountRequestPageTranslations[0]).some(item => item === country.countryWithLangCode)));
    };
    const getIndividualRole = async () => {
      let userMail = "";
      let userFirstName = "";
      let userLastName = "";
      let userLoginMethod = "";
      let profileData = localStorage.getItem("userProfile");
      let user_token = localStorage.getItem("email");
      let userProfile = profileData ? JSON.parse(profileData) : "";
      
      userMail = userProfile.email;
      userFirstName = userProfile.firstName;
      userLastName = userProfile.lastName;
      userLoginMethod = userProfile.loginProvider;
    
      const individualRolesUrl = `${config.baseApi}dc/userdata/${user_token}/checkUser`;
    
      try {
        const response = await axios({
          method: "post",
          url: individualRolesUrl,
          data: {
            "EMAILID": userMail,
            "COUNTRYCODE": localStorage.getItem('previousLanguage')? localStorage.getItem('previousLanguage'): getCountryCode(), 
            "USERNAME": `${userFirstName} ${userLastName}`,
            "LOGINMETHOD": userLoginMethod
          },
          headers: {
            "X-Country-Code": getCountryCode(),
            "urlDetermination" : window.location.origin.toLowerCase(),
          }
        });
        user_country = response.data.data
        setCountries(user_country?.regionCluster);
        setUserRoleId(user_country?.RoleId)
        setUserEmailId(user_country?.SK)
        return response;
      } catch (error) {
        console.error("Failed to fetch individual role:", error);
      }
    };
    getIndividualRole()
    countrySettings()
    setCountries(user_country?.regionCluster);
    setLanguages(country_data?.languageDropdownData.filter(country =>Object.keys(country_data?.accountRequestPageTranslations[0]).some(item => item === country.countryWithLangCode)));
  }, [countryCode]);

  const languageSelector = () => {
    let languages = [];
    if (languageSet !== undefined && languageSet !== null) {
      languageSet.forEach(language =>
        languages.push(
          <li
            lang-code={language.langCode}
            language={language.lang}
            countryWithLangCode= {language.countryWithLangCode}
            onClick={(e) => languageSetter(e, language.lang)}
            className={selectedLang === language.lang ? 'highlighted' : ''}
          >
            {language.lang}
          </li>
        )
      );
    }
    async function languageSetter(e,langCode) {
      let lang_code = e.target.getAttribute("lang-code");
      setSelectedLang(langCode); 
      const defaultLangres = await axios({
        method: "get",
        url: `${config.favApi}dc/userdata/${userId}/settings`,
      });
    let intialLocale = defaultLangres?.data?.data?.settings?.defaultLocale
     // let lang_code = e.target.getAttribute("countryWithLangCode");
      i18n.changeLanguage(lang_code);
      axios({
        method: "post",
        url: `${config.favApi}dc/userdata/${userId}/settings`,
        data: {
          settings: {
            defaultLang: lang_code,
            defaultLocale : intialLocale

          }
        },
        headers: {
          "X-Country-Code": `${countryCode ? countryCode : getCountryCode()}`
        }
      });
      localStorage.setItem("LegalTextLanguageFilter",countrySettings && countrySettings?.languages?.some(language => language?.langCode === lang_code) ? countrySettings?.languages?.filter(language => language?.langCode === lang_code)[0]?.lang : "English")
    }
    return languages;
  };

  const countrySelector = () => {
    let countries = [];
    if (countriesSet !== undefined && countriesSet !== null) {
      countriesSet.forEach(country =>
        countries.push(
          <li
            country-code={country.countryCode}
            country={country.countryCode}
            countrywithcountryname= {country.countryName}
            onClick={(e) => languageSetter(e, country.countryCode)}
            className={selectedCountry === country.countryCode.toLowerCase() ? 'highlighted' : ''}
          >
            {country.countryName}
          </li>
        )
      );
    }
    async function languageSetter(e, countryCode) {
      let country_code = e.target.getAttribute("country-code");
      setSelectedCountry(countryCode.toLowerCase()); 
      axios({
        method: "post",
          url: `${config.baseApi}dc/exports/updateuserrole`,
          data: {
           "gigyaId": userEmailId,
            "emailId": userEmailId,
            "roleId": userRoleId,
            "country": country_code,
            "regionCluster":countriesSet
          },
          headers: {
            "X-Country-Code": `${getCountryCode()}`
          }
          }).then(async() => {
        
      // getUsers(countryId.id ? countryId.id  : getCountryCode())
         // postGigyaID()
          const brandNameUrl = `${config.baseApi}dc/brands`;
          const dataUrl = `${config.baseApi}dc/entities`;
          const fileTypesUrl = `${config.baseApi}dc/assets/filetypes`;
          const countrySettingsUrl = `${config.baseApi}dc/config/country`;
          function brandList() {
            return axios.get(brandNameUrl, {
              headers: {
           
                "X-Country-Code": `${country_code ? country_code  : getCountryCode()}`
              }
            }).then(res => res.data).then(response => {
              let products = [];
              let assets = [];
              const brands = response.data.filter;
             
              const removeDuplicates=(array, key) =>{
                return array.filter(
                  (obj, index, self) =>
                    index ===
                    self.findIndex(
                      (item) => item[key].toLowerCase() === obj[key].toLowerCase()
                    )
                );
              }
            
              const formatBrandName=(name)=> {
                return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
              }
              const uniqueBrands = removeDuplicates(brands, 'brandName');
          
              let formattedBrands = uniqueBrands.map(brand => ({
                brandName: formatBrandName(brand.brandName),
                filterType: brand.filterType,
                count: brand.count
              }));
            
          
              store.dispatch(setFilterBrandName(formattedBrands));
              const brandSets = _.chunk(brands, 2);
              const promiseArray = brandSets.map(brands =>
                axios.post(
                  dataUrl,
                  {
                    brandNames: brands.map(brand => brand.brandName)
                  },
                  {
                    headers: {
                    
                      "X-Country-Code": `${country_code ? country_code  : getCountryCode()}`
                    }
                  }
                )
              );
              return Promise.allSettled(promiseArray).then(response => {
                response.forEach(obj => {
                  if (obj.status === "fulfilled") {
                    products.push(...obj.value.data.data.products);
                    (RoleSettings?.roleName === "Global Admin" || RoleSettings?.roleName === "Regional Admin" || RoleSettings?.roleName === "Country Admin")
                    ? products = products.filter(item => typeof item?.plcstatus === "string" ?  item?.plcstatus?.toLowerCase().includes("ready for publication") : item?.plcstatus?.['en-ZZ']?.toLowerCase().includes("ready for publication"))
                    : products = products.filter(item => typeof item?.plcstatus === "string" ?  item?.plcstatus?.toLowerCase().includes("ready for publication") : item?.plcstatus?.['en-ZZ']?.toLowerCase().includes("ready for publication") &&  (item?.hideShowStatus?.[0]?.currentStatus ==="show" || !item?.hideShowStatus) )
                    assets.push(...obj.value.data.data.assets);
                     // eslint-disable-next-line no-unused-expressions
                    (RoleSettings?.roleName === "Global Admin"  || RoleSettings?.roleName === "Regional Admin"|| RoleSettings?.roleName === "Country Admin")
                    ? assets 
                    : assets = assets.filter(item =>  !item?.hideShowStatus || item?.hideShowStatus?.[0]?.currentStatus ==="show"  )
          
                  }
                  if (obj.status === "rejected") {
                    console.log(obj.reason);
                  }
                });
                store.dispatch(fetchProductsSuccess(products));
                store.dispatch(fetchAssetsSuccess(assets));
                let asset_types = [];
                assets.forEach(asset =>
                  asset.asset_type.forEach(val =>
                    asset_types.push({ asset_type: val, filterType: "assetType" })
                  )
                );   
                let unique_asset_types = _.uniqBy(asset_types, "asset_type.dcAssetType");
          
                const updatedArray = unique_asset_types.map(item => {
                  if (
                    item.asset_type &&
                    item.asset_type.dcAssetType === 'eCommerce Product Shots'
                  ) {
                    return {
                      ...item,
                      asset_type: {
                        ...item.asset_type,
                        dcAssetType: 'Product Shot',
                      },
                    };
                  } else {
                    return item;
                  }
                });
                let updatedArray_unique_asset_types = _.uniqBy(updatedArray, "asset_type.dcAssetType");
          
                store.dispatch(setFilterAssetTypes(updatedArray_unique_asset_types));
             
              });
            })
          
          }
          function fileType() {
            return axios.get(fileTypesUrl, {
              headers: {
                "X-Country-Code": `${country_code ? country_code  : getCountryCode()}`
              }
            }).then(res => res.data).then(response => {
              const fileTypes = response.data;
              store.dispatch(setFilterFileTypes(fileTypes));
            }).catch(error => {
              console.log(error.message);
            });;
          }
          
          const countrySettingsNew = async load => {
            let country_settingsNew = await axios({
              method: "get",
              url: `/${country_code? country_code  : getCountryCode()}`,
              baseURL: countrySettingsUrl,
              headers: { Authorization: "Bearer" }
            });
            let country_data = country_settingsNew.data.data;
            store.dispatch(fetchCountrySettingsSuccess(country_data));
        
           
            return country_data;
          };
          
          brandList()
          fileType()
          countrySettingsNew()
          localStorage.setItem('previousLanguage', country_code ? country_code: localStorage.getItem('previousLanguage')  );
          localStorage.setItem('checkUser',country_code ? country_code : localStorage.getItem('checkUser')) 

      })
    }
    return countries;
  };

  function handleResult(eventObj) {
    if (eventObj.reason === "missing" || eventObj.reason === "finished") {
      window.location.href = "/";
    }
  }
  return (
    
  <div className="profilePageContainer container">
    <div>
   <div className="translatedTitle">
    <span>{t(`Profile Page.profile_details`)}</span></div>
    <div id="profilePageScreen" className="profilePageScreen"></div>
    </div>
    <div style={{width:"100%", margin: "50px 0 30px 30px"}}>
      
    <span className="title">{t(`Profile Page.settings_title`)}</span>
    <div className ={(JSON.parse(localStorage.getItem('user_role')) === "Regional Admin" || JSON.parse(localStorage.getItem('user_role')) === "Internal")
              &&  (countriesSet && countriesSet?.length > 0) 
               ? "addingCountry":"onlyLanguage"}>
    <div className="dc-user-settings">
            <div className="lang-switcher">
              <Accordion
                title={t(`Profile Page.language_switcher`).toUpperCase()}
                content={languageSelector()}
              />
            </div>
            
    </div>
          {(JSON.parse(localStorage.getItem('user_role')) === "Regional Admin" || JSON.parse(localStorage.getItem('user_role')) === "Internal") && 
              (countriesSet && countriesSet?.length > 0) ? (
                <div className="dc-user-settings">
                  <div className="lang-switcher">
                    <Accordion
                      title={t('Profile Page.country_switcher').toUpperCase()}
                      content={countrySelector()}
                    />
                  </div>
                </div>
              ) : null}
    </div>
    </div>
  </div>
  );
};

const mapStateToProps = state => ({
  RoleSettings: state.role.countryData,
  countrySettings: state.country.countryData,
  countryLocale: state.locale.countryLocale,
  countryCode: state.countryCode.countryCode,
});

const mapDispatchToProps = dispatch => {
  return {
   
    updateRenderCountryCode: ownProps => dispatch(updateRenderCountryCode(ownProps))
  };
};
// export default compose(
//   connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )
// )(ProfilePage);
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
