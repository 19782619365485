// import React, { useState, useMemo, useEffect } from 'react';
import React, { useState, useEffect, useMemo } from "react";
import Pagination from './Pagination';
import CardComponent from "../Card/card.jsx";
//import { useTranslation } from "react-i18next";
import './PaginatedItem.scss';
//import AuthContext from "../../components/Auth/Auth-context";
import axios from "axios";
import config from "../../config.js";
import {getCountryCode} from "../../util/Helper.js";
//import SearchResult from '../SearchResult/searchResult';





export default function App({ itemsPerPage, displayProducts, displayAssets, sendingProps,classValue }, prop) {
    
    let data = []
    let filteredDataLength = 0
    //const { t } = useTranslation('common');
    let PageSize=itemsPerPage
    const [currentPage, setCurrentPage] = useState(1);
    const [gigyaRole, setGigyaRole] = useState();
    //const AuthCtx = useContext(AuthContext);
    //const userId = AuthCtx.giveToken();
    const userId= localStorage.email
    const postGigyaID = async () => {
        const response = await axios({
          method: "get",
          url: `${config.baseApi}dc/exports/getrolepermission/${userId}`,
          data: {
            "useAdministration": {
              "Gigya_ID": userId,
            }
          },
          headers: {
            "X-Country-Code": `${getCountryCode()}`
          }
        })
        setGigyaRole(response.data.data?.roleName);
      }

      if (displayProducts.length > 0 && displayAssets.length > 0) {
        let filteredDisplayProducts = []
        let filteredDisplayAssets =[]
        if(gigyaRole === "Global Admin" || gigyaRole === "Regional Admin" ||  gigyaRole === "Country Admin"){
            filteredDisplayProducts =    displayProducts && displayProducts?.filter(item => 
                typeof item?.plcstatus === "string" 
                  ? item?.plcstatus?.toLowerCase().includes("ready for publication") 
                  : Array.isArray(item?.plcstatus?.['en-ZZ']) 
                    ? item?.plcstatus?.['en-ZZ'].some(status => 
                        JSON.stringify(status)?.toLowerCase().includes("ready for publication")
                      ) 
                    : JSON.stringify(item?.plcstatus?.['en-ZZ'])?.toLowerCase().includes("ready for publication")
              )
                 }
        else{
            filteredDisplayProducts = displayProducts && displayProducts?.filter(item => 
                typeof item?.plcstatus === "string" 
                  ? item?.plcstatus?.toLowerCase().includes("ready for publication") 
                  : Array.isArray(item?.plcstatus?.['en-ZZ']) 
                    ? item?.plcstatus?.['en-ZZ'].some(status => 
                        JSON.stringify(status)?.toLowerCase().includes("ready for publication")
                      ) 
                    : JSON.stringify(item?.plcstatus?.['en-ZZ'])?.toLowerCase().includes("ready for publication") && 
                      (item?.hideShowStatus?.[0]?.currentStatus === "show" || !item?.hideShowStatus)
              )
        }
    
      
         
        if(gigyaRole === "Global Admin" || gigyaRole === "Regional Admin" ||  gigyaRole === "Country Admin"){
            filteredDisplayAssets = displayAssets
        }
        else{
            filteredDisplayAssets =  displayAssets.filter(item =>  !item?.hideShowStatus || item?.hideShowStatus?.[0]?.currentStatus ==="show"  )
        }
      
        data = filteredDisplayProducts.concat(filteredDisplayAssets)      
        filteredDataLength= data.length 
    }
    else {
        if (displayProducts.length > 0) {
            let filteredDisplayProducts = []
            if(gigyaRole === "Global Admin" || gigyaRole === "Regional Admin" ||  gigyaRole === "Country Admin"){
                filteredDisplayProducts =    displayProducts && displayProducts?.filter(item => 
                    typeof item?.plcstatus === "string" 
                      ? item?.plcstatus?.toLowerCase().includes("ready for publication") 
                      : Array.isArray(item?.plcstatus?.['en-ZZ']) 
                        ? item?.plcstatus?.['en-ZZ'].some(status => 
                            JSON.stringify(status)?.toLowerCase().includes("ready for publication")
                          ) 
                        : JSON.stringify(item?.plcstatus?.['en-ZZ'])?.toLowerCase().includes("ready for publication")
                  )            }
            else{
                filteredDisplayProducts = displayProducts && displayProducts?.filter(item => 
                    typeof item?.plcstatus === "string" 
                      ? item?.plcstatus?.toLowerCase().includes("ready for publication") 
                      : Array.isArray(item?.plcstatus?.['en-ZZ']) 
                        ? item?.plcstatus?.['en-ZZ'].some(status => 
                            JSON.stringify(status)?.toLowerCase().includes("ready for publication")
                          ) 
                        : JSON.stringify(item?.plcstatus?.['en-ZZ'])?.toLowerCase().includes("ready for publication") && 
                          (item?.hideShowStatus?.[0]?.currentStatus === "show" || !item?.hideShowStatus)
                  )
                            }
            
            data = filteredDisplayProducts
            filteredDataLength= data.length

        }
        if (displayAssets.length > 0) {
            
            if(gigyaRole === "Global Admin" || gigyaRole === "Regional Admin" ||  gigyaRole === "Country Admin"){
                data = displayAssets
            }
            else{
                data =  displayAssets.filter(item =>  !item?.hideShowStatus || item?.hideShowStatus?.[0]?.currentStatus ==="show"  )
            } 
         
           //data = displayAssets

            filteredDataLength= data.length
        }

    }


    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    const currentTableData = useMemo(() => {
        return data.slice(firstPageIndex, lastPageIndex);
// eslint-disable-next-line 
    }, [currentPage, displayProducts, displayAssets]);

    useEffect(() => {
        // callingFunc()
        setCurrentPage(1)

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayProducts.length, displayAssets.length])
    useEffect(() => {

        sendingProps(filteredDataLength)
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredDataLength,displayProducts.length, displayAssets.length])
   // const totalNumOfProductsAssets = displayProducts.length + displayAssets.length
   useEffect(() => {
    postGigyaID();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 
    
    return (
        <>
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={data.length}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
            {currentTableData.map((value, index) => {
                return (
                    <div className={classValue}>
                        <CardComponent item={value} index={(12*(currentPage - 1)) + index} />                       
                    </div>
                );
            })}
            {/* <div>
                <table className='NumOfProducts'>
                    <td>{totalNumOfProductsAssets > lastPageIndex ? lastPageIndex : totalNumOfProductsAssets}</td>
                    <td>{" "}{t("SearchPage.results")}{" "}</td>
                    <td>out of </td>
                    <td>{displayProducts.length + displayAssets.length}{" "}
                        {t("SearchPage.results")}
                    </td>
                </table>
            </div> */}
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={data.length}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />

        </>
    );
}
