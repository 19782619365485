import React, { useContext, useState, useEffect, useRef } from "react";
import AuthContext from "../../components/Auth/Auth-context";
import { getFavoritesList, getCartList } from "../../Services/service.js";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import DCLogo from "../../assets/DC-logo.png";
import "./header.scss";
import ListItemText from "@material-ui/core/ListItemText";
import AppMenuItemComponent from "../AppMenu/AppMenuItemComponent";
import InternalSearch from "../Search/search";
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";
import Dropdown from "react-bootstrap/Dropdown";
// import Skeleton from "react-loading-skeleton";
// import { ProgressLoader } from "../ProgressLoader/progressLoader";
import { useHistory } from "react-router-dom";
import { routeConfig } from "../../util/Helper.js";
import Breadcrumbs from "../Breadcrumb/breadcrumb.jsx";
import HamBurgerMenu from "../HambugerMenu/hamBurger.jsx";
import qs from "qs";
import { useMediaQuery } from "react-responsive";
import { assetCategories, dynamicAssetTitle } from "../../util/Helper.js";
import ECommerce from "../../assets/E-Commerce.jpg";
import BrandMaterials from "../../assets/BrandMaterials.jpg";
import OnlineAdvertising from "../../assets/OnlineMarketing.jpg";
import Patients from "../../assets/Patient.jpg";
import HCP from "../../assets/Fachpersonal_HCP.jpg";
import Press from "../../assets/Press.jpg";
import Pharmacy from "../../assets/Apotheke_Pharmacy.jpg";
import {getCountryCode} from "../../util/Helper.js";
import config from "../../config.js";
import NotificationsPage from "../Notifications/notifications"
import ShoppingCartPage from "../../pages/ShoppingCartPageView/ShoppingCartPage";
import LocaleDropdown from "../../pages/LocaleDropdown/LocaleDropdown";
const env = process.env.REACT_APP_ENVIRONMENT;
let ga_env = env === "PROD" ? "Production" : "Testing";
window.dataLayer = window.dataLayer || [];
const axios = require("axios");

const Header = props => {
  //let viewPermissionData ={}
  let { brandList, assetType, match, countrySettings, RoleSettings,countryLocale } = { ...props };
  const { t } = useTranslation("common");
  let history = useHistory();
  const brandContainer = React.createRef();
  const assetContainer = React.createRef();
  const [brandToggle, setBrandToggle] = useState(false);
  const [gigyaRole, setGigyaRole] = useState();
  const [assetToggle, setAssetToggle] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const [breadcrumb, setBreadCrumb] = useState([]);
  const [viewPermissionData, setviewPermissionData] = useState([])
  const [assetCategoriesList, setAssetCategoriesList] = useState(null);
  //const [translatedAssetList, setTranslatedAsstList] = useState(null);
  //const [assetList, setAssetList] = useState(null);
  const headerRef = useRef(null);
  const mobileHeaderRef = useRef(null);
  const AuthCtx = useContext(AuthContext);
  // const userId = AuthCtx.giveToken();
  //const assetTypeList = [...assetType];

  useEffect(() => {
    //setAssetList(countrySettings.assetTypeMap);
    if (Object.keys(countrySettings).includes("assetTypeMap")) {
      const translatedAssetList = countrySettings.assetTypeMap.map(i => {
        let translate_key = `Asset Type.${i.dcAssetType}`;
        return {
          ...i,
          translated: t(translate_key)
        };
      });

      setAssetCategoriesList(assetCategories(translatedAssetList, assetType));
    }

  }, [countrySettings, assetType, t]);

  const normalValueShow =(item, particularAttribute, countryLocale)=>{
 
    return(
      typeof item?.[particularAttribute] === "string"
      ? item?.[particularAttribute]  : countryLocale &&  item?.[particularAttribute]?.[countryLocale] ?
      item?.[particularAttribute]?.[countryLocale]  : item?.[particularAttribute]?.['en-ZZ']
    )
  }

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1025px)"
  });

  useEffect(() => {
    const generateBreadCrumb = () => {
      const routeSet = routeConfig(t);
      let crumbs = [];
      routeSet
        .filter(({ path }) => {
          if (window.location.pathname.includes(path)) {
            return true;
          }
          if (
            path.includes("search-page") &&
            window.location.pathname.includes("search-page")
          ) {
            return true;
          }
          if (
            path.includes("product-detail-page") &&
            window.location.pathname.includes("product-detail-page")
          ) {
            return true;
          }
          if (
            path.includes("asset-detail-page") &&
            window.location.pathname.includes("asset-detail-page")
          ) {
            return true;
          }
          return false;
        })
        .map(crumb => {
          let { path, name } = { ...crumb };
          let location = window.location.pathname;
          if (
            (location.includes("search-page") &&
              location.includes("product-detail-page")) ||
            (location.includes("search-page") &&
              location.includes("asset-detail-page")) ||
            location.includes("asset-detail-page") ||
            location.includes("product-detail-page")
          ) {
            let searchParam, item;
            if (props.history.location.state !== undefined)
              searchParam = props.history.location.state.queryParams;
            else {
              let id = props.location.pathname.split("/").pop();
              item = location.includes("product-detail-page")
                ? props.productsList.filter(product => product.id === id)
                : props.assetsList.filter(asset => asset.id === id);
              if (item.length > 0)
                searchParam = { brand: [normalValueShow(item[0],"brand",countryLocale).trim()] };
            }
            crumb.path = path.replace("paramString", qs.stringify(searchParam));
            if (name.length === 0) {
              if (props.history.location.state !== undefined){
                console.log("nameabc",props.productsList.filter(product => product.id === props.history.location.state.id)[0],location)
                crumb.name = location.includes("product-detail-page")
                 ? normalValueShow(props.productsList.filter(product => product.id === props.history.location.state.id)[0],"ptitle",countryLocale)
                 : props.history.location.state.title
              }               
              else {
                if (item.length > 0)
                  crumb.name = location.includes("product-detail-page")
                    ? normalValueShow(item[0],"ptitle",countryLocale)
                    : dynamicAssetTitle(item[0], t, props.productsList);
              }
            } else {
          
              crumb.name = name;
            }
            crumbs.push(crumb);
          } else {
            crumb.path = window.location.search.length
              ? Object.keys(
                  qs.parse(window.location.search, { ignoreQueryPrefix: true })
                ).reduce(
                  path => path.replace("?paramString", window.location.search),
                  path
                )
              : path;
            crumb.name = name.length
              ? name
              : props.history.location.state.title;
            crumbs.push(crumb);
          }
          return null;
        });

      setBreadCrumb(crumbs);
    };

    generateBreadCrumb();

    const trackPageView = () => {
      window.dataLayer.push({
        event: "pageview",
        eventCategory: "Page View",
        eventAction: window.location.pathname,
        eventLabel: "Page View",
        eventValue: window.location.href,
        environment: ga_env
      });
    };

    trackPageView();

    history.listen(() => {
      generateBreadCrumb();
      trackPageView();
    });
  }, [
    history,
    t,
    props.productsList,
    props.assetsList,
    props.history.location.state,
    props.location.pathname,
    countryLocale
  ]);
  const userId= localStorage.email
  const postGigyaID = async () => {
    const response = await axios({
      method: "get",
      url: `${config.baseApi}dc/exports/getrolepermission/${AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : userId }`,
      data: {
        "useAdministration": {
          "Gigya_ID": AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : userId ,
        }
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    setGigyaRole(response.data.data?.roleName);
    localStorage.setItem('user_role',JSON.stringify(response?.data?.data?.roleName))
    //store.dispatch(fetchRoleSettingsSuccess(response.data.data?.viewPermission));
    setviewPermissionData(response.data.data?.viewPermission)
  }
  useEffect(() => {
     postGigyaID();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('previousLanguage'), localStorage.getItem('email'), userId, localStorage.getItem("user_role")
    
  ]);
  
  /*   let key_store = Object.keys(assetCategoriesList);
  key_store.forEach(key => {
    temp = {
      ...assetCategoriesList,
      key: {
        ...assetCategoriesList[key],
        asset_list: Array.from(assetCategoriesList[key].asset_list).sort(
          (a, b) => {
            return a.translated.localeCompare(b.translated, "de", {
              sensitivity: "base"
            });
          }
        )
      }
    };
  }); */

  /* const sortedAssetList = Array.from(translatedAssetList).sort((a, b) => {
    return a.translated.localeCompare(b.translated, "de", {
      sensitivity: "base"
    });
  }); */

  const handleScroll = () => {
    if (headerRef.current) {
      setSticky(headerRef.current.getBoundingClientRect().top <= 0);
    }
    if (mobileHeaderRef.current) {
      setSticky(mobileHeaderRef.current.getBoundingClientRect().top < 0);
    }
  };

  const handleBrandClick = () => {
    setBrandToggle(prevState => !prevState);
  };

  const handleAssetClick = () => {
    setAssetToggle(prevState => !prevState);
  };

  const handleClickOutside = event => {
    if (
      event.target.parentElement.getAttribute("class") !==
      "MuiListItemText-root"
    ) {
      if (
        brandContainer.current &&
        !brandContainer.current.contains(event.target)
      ) {
        setBrandToggle(false);
      }

      if (
        assetContainer.current &&
        !assetContainer.current.contains(event.target) &&
        !event.target.classList.contains("category-text") &&
        !event.target.classList.contains("asset-image")
      ) {
        setAssetToggle(false);
      }
    }
  };

  document.addEventListener("mousedown", handleClickOutside);

  //const userId = AuthCtx.giveToken();

  useEffect(() => {
    if (userId && userId.length > 0) {
      getFavoritesList(userId);
      getCartList(userId)
    }
  }, [userId]);

  useEffect(() => {
    const appContainer = document.querySelector(".App");
    appContainer.addEventListener(
      "scroll",
      function() {
        handleScroll();
      },
      false
    );
    return () => {
      appContainer.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  const feedbackPrompt = () => {
    history.push("/feedback");
  };

  const gigya = window.gigya;

  const logOutOnClick = () => {
    const provider = AuthCtx.giveProvider();
    if (provider === "site") {
      gigya.accounts.logout({
        callback: function(response) {
          if (response.errorCode === 0) {
            AuthCtx.logout();
            feedbackPrompt();
          }
        }
      });
    } else {
      gigya.socialize.logout({
        callback: function(response) {
          if (response.errorCode === 0) {
            AuthCtx.logout();
            feedbackPrompt();
          }
        }
      });
    }
  };

  const handleClick = event => {
    let entity_type = "",
      entity_val = "";

    if (event.currentTarget.hasAttribute("type")) {
      entity_val = event.currentTarget.getAttribute("value");
      if (event.currentTarget.getAttribute("type") === "brand") {
        entity_type = "Brand";
        setBrandToggle(false);
      } else {
        entity_type = "Asset Type";
        setAssetToggle(false);
      }

      window.dataLayer.push({
        event: "gaGenericEvent",
        eventCategory: "Filter",
        eventAction: entity_val,
        eventLabel: entity_type,
        environment: ga_env
      });
    }
  };

  const profile = () => {
    let userProfile =
      AuthCtx.userProfile !== undefined && AuthCtx.userProfile !== null
        ? AuthCtx.userProfile
        : "";
    if (userProfile === "") {
      let profileData = localStorage.getItem("userProfile");
      userProfile =
        profileData !== undefined && profileData !== null
          ? JSON.parse(profileData)
          : "";
    }
    return `${t("Miscellaneous.user-greeting")} ${userProfile.firstName} ${
      userProfile.lastName
    } `;
  };

  const handleCategory = category => {
    let { path } = { ...match };
    path =
      path.charAt(path.length - 1) === "/"
        ? path.substring(0, path.length - 1) + "" + path.substring(path.length)
        : path;

    let asset_type_list = assetCategoriesList[category].asset_list;
    let paramObj = {};
    const paramType = "assettype";
    paramObj[paramType] = [];
    asset_type_list.forEach(asset =>
      paramObj[paramType].push(asset.dcAssetType)
    );
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Filter",
      eventAction: category,
      eventLabel: "Asset Type Category",
      environment: ga_env
    });
    props.history.push(`${path}/search-page/?${qs.stringify(paramObj)}`);
    setAssetToggle(false);
  };

  const setAssetCategoryImage = category => {
    let image = "";
    switch (category) {
      case "E-Commerce":
        image = (
          <img
            className="asset-image"
            onClick={() => handleCategory(category)}
            src={ECommerce}
            alt="E-Commerce"
          />
        );
        break;
      case "Online Advertising":
        image = (
          <img
            className="asset-image"
            src={OnlineAdvertising}
            alt="Online-Advertising"
          />
        );
        break;
      case "Pharmacy":
        image = <img className="asset-image" src={Pharmacy} alt="Pharmacy" />;
        break;
      case "HCP":
        image = <img className="asset-image" src={HCP} alt="HCP" />;
        break;
      case "Patients":
        image = <img className="asset-image" src={Patients} alt="Patients" />;
        break;
      case "Brand Materials":
        image = (
          <img
            className="asset-image"
            src={BrandMaterials}
            alt="Brand-Materials"
          />
        );
        break;
      case "Press":
        image = <img className="asset-image" src={Press} alt="Press" />;
        break;
      default:
        break;
    }
    return image;
  };

  if (isDesktopOrLaptop) {
    return (
      <Container fluid className="header">
        <Row className="top-header">
          <Col className="user-navigation" xs={{ span: 5, offset: 7 }}>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">{profile()}</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/profile-page`}>
                  <span>
                    <i className="bi bi-person-circle"></i>
                  </span>
                  {t("Header.my-profile")}
                </Dropdown.Item>
                {(gigyaRole === "Global Admin" || gigyaRole === "Country Admin" || gigyaRole === "Regional Admin"|| gigyaRole === "Retailer") ? (
                <Dropdown.Item  as={Link} to={`/my-exports`}>
                  <span>
                  <i class="bi bi-folder2"></i>
                  </span>
                My Exports
                </Dropdown.Item>):""}
                {(gigyaRole=== "Global Admin" || gigyaRole === "Country Admin" || gigyaRole === "Regional Admin"|| gigyaRole === "Internal") ? (
                <Dropdown.Item as={Link} to={`/administration`}>
                  <span>
                  <i class="bi bi-person-fill"></i>
                  </span>
                  {t("Header.administration")}
                </Dropdown.Item>):""}
                <Dropdown.Item onClick={logOutOnClick}>
                  <span>
                    <i className="bi bi-power"></i>
                  </span>
                  {t("Header.logout")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <div
          className={`sticky-wrapper${isSticky ? " sticky" : ""}`}
          ref={headerRef}
        >
          <div className="bottom-header">
            <div className="Dc-logo">
              <span className="helper"></span>
              <Link to="/home">
                <img src={DCLogo} alt="DC-Logo" />
              </Link>
            </div>
            {/* <ProgressLoader
              area="initial-loading"
              skeleton={dummySkeletonHeader()}
              render={
              
              }
            /> */}
              <>
                {(RoleSettings && RoleSettings['Look up product data/assets'] ) || (viewPermissionData && viewPermissionData['Look up product data/assets']) === true?
                  <div
                    onClick={handleBrandClick}
                    className="brand"
                    ref={brandContainer}
                  >
                    {t("Header.brand")}
                    {!brandToggle && (
                      <FontAwesomeIcon
                        className="brand-icon"
                        icon={faChevronDown}
                      />
                    )}
                    {brandToggle && (
                      <FontAwesomeIcon
                        className="brand-icon"
                        icon={faChevronUp}
                      />
                    )}
                  </div>
                  :""}
                   {(RoleSettings && RoleSettings['Look up marketing assets'] ) || (viewPermissionData && viewPermissionData['Look up marketing assets'])=== true?
                  <div
                    onClick={handleAssetClick}
                    className="asset"
                    ref={assetContainer}
                  >
                    {t("Assets")}
                    {!assetToggle && (
                      <FontAwesomeIcon
                        className="brand-icon"
                        icon={faChevronDown}
                      />
                    )}
                    {assetToggle && (
                      <FontAwesomeIcon
                        className="brand-icon"
                        icon={faChevronUp}
                      />
                    )}
                  </div>
                  :""}
                  {(RoleSettings && RoleSettings['Mark favourites']) || (viewPermissionData && viewPermissionData['Mark favourites'])=== true? 
                  <div className="my-bookmarks">
                  <Link style={{ color: '#00617f',  textDecoration : 'none'}} to={`/favorites-page`}>{t("Header.favorites")}</Link>
                  </div>
                  : ""}
                  {(gigyaRole === "Retailer") ?(
                  <div className="my-bookmarks">
                
                  <Link style={{ color: '#00617f',  textDecoration : 'none'}} to={`/my-exports`}>My Exports</Link>
                 
                  </div> 
                  )
                  :""}
                   {(RoleSettings && RoleSettings['Content notifications']) ||( viewPermissionData && viewPermissionData['Content notifications'])=== true? 
                  <div className="notifications">
                    <NotificationsPage/>
                  </div>
                  : ""}
                <div className="ShoppingCart">
                    <ShoppingCartPage/>
                  </div>
                  <div className="search">
                    <InternalSearch />
                  </div>
                  <div className="LocaleDropdown">
                    <LocaleDropdown/>
                  </div>
                </>
            <div className="Bayer-logo">
              <span className="helper"></span>
              <a
                href="https://www.bayer.com/de/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://shared.bayer.com/img/logo.svg"
                  alt="Bayer-Logo"
                />
              </a>
            </div>
          </div>
        </div>
        {breadcrumb.length > 1 && (
          <div className="bread-crumb-container">
            {<Breadcrumbs crumbs={breadcrumb} />}
            {console.log("breads",breadcrumb)}
          </div>
        )}
        {brandToggle && (
          <Row className={`brand-dropdown${isSticky ? " sticky" : ""}`}>
            <h2>
              <strong>{t("Header.brand")}</strong>
            </h2>

            <div className="list-group">
              <Scrollbars style={{ width: "100%", height: 300 }}>
                {brandList
                  .filter(brand => {
                    let flag;
                    if (Object.keys(brand).includes("brandName")) {
                      normalValueShow(brand,"brandName",countryLocale).trim() !== ""
                        ? (flag = true)
                        : (flag = false);
                    }
                    if (Object.keys(brand).includes("sub_brand")) {
                      normalValueShow(brand,"sub_brand",countryLocale).trim() !== ""
                        ? (flag = true)
                        : (flag = false);
                    }
                    if (flag === true) {
                      return true;
                    } else {
                      return false;
                    }
                  })
                  .map(brand => {
                    let brandName;
                    if (Object.keys(brand).includes("brandName")) {
                      brandName =normalValueShow(brand,"brandName",countryLocale);
                    }
                    if (Object.keys(brand).includes("sub_brand")) {
                      brandName = normalValueShow(brand,"sub_brand",countryLocale);
                    }
                    return (
                      <AppMenuItemComponent
                        className="brand-menu"
                        value={brandName}
                        key={brandName}
                      >
                        <ListItemText
                          onClick={event => handleClick(event)}
                          primary={brandName}
                          value={brandName}
                          type="brand"
                        />
                      </AppMenuItemComponent>
                    );
                  })}
              </Scrollbars>
            </div>
          </Row>
        )}

        {assetToggle && (
          <Row className={`asset-dropdown${isSticky ? " sticky" : ""}`}>
            <div className="container">
              <div className="asset-menu-container row">
                {Object.values(assetCategoriesList).map(entry => (
                  <div
                    className={`asset-component-wrapper column-${
                      Object.values(assetCategoriesList).length
                    }`}
                  >
                    <div
                      className="asset-category-image"
                      onClick={() => handleCategory(entry.category)}
                    >
                      {setAssetCategoryImage(entry.category)}
                    </div>
                    <h2
                      className="category-text"
                      onClick={() => handleCategory(entry.category)}
                    >
                      {t(`Asset Category.${entry.category}`)}
                    </h2>
                    <div className="asset-type-list">
                      <div className="list-group">
                        <Scrollbars style={{ width: "100%", height: 300 }}>
                          {entry.asset_list
                            .filter(asset => {
                              if (asset.dcAssetType.trim() !== "") {
                                return true;
                              }
                              return false;
                            })
                            .map(asset => (
                              <AppMenuItemComponent
                                className="asset-menu"
                                value={asset.dcAssetType}
                                key={asset.dcAssetType}
                              >
                                {" "}
                                <ListItemText
                                  onClick={event => handleClick(event)}
                                  primary={t(`Asset Type.${asset.dcAssetType}`)}
                                  value={asset.dcAssetType}
                                  type="asset"
                                />
                              </AppMenuItemComponent>
                            ))}
                        </Scrollbars>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Row>
        )}
      </Container>
    );
  } else {
    return (
      <div
        className={`sticky-wrapper${isSticky ? " sticky" : ""}`}
        ref={mobileHeaderRef}
      >
        <Container fluid className="mobile-header">
          <div className="hamburger-menu">
            <div className="right">
              <HamBurgerMenu
                brandNames={brandList}
                assetTypeNames={assetType}
              />
            </div>
          </div>
          <div className="bayer-logo">
            <span className="helper"></span>
            <a
              href="https://www.bayer.com/de/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://shared.bayer.com/img/logo.svg"
                alt="Bayer-Logo"
              />
            </a>
          </div>
          <div className="header-options">
            <div className="favorites">
              {" "}
              <Link to={`/favorites-page`}>
                {" "}
                <span>
                  <i className="bi bi-bookmark-fill"></i>
                </span>
              </Link>
            </div>
            <div className="profile">
              <Link to={`/profile-page`}>
                <span>
                  <i className="bi bi-person-circle"></i>
                </span>
              </Link>
            </div>
            {(gigyaRole=== "Global Admin" || gigyaRole === "Country Admin" || gigyaRole === "Regional Admin" || gigyaRole === "Retailer") ? (
            <div className="profile">
              <Link to={`/my-exports`}>
                <span>
                <i class="bi bi-folder2"></i>
                </span>
              </Link>
            </div>):""}
            {(gigyaRole=== "Global Admin" || gigyaRole === "Country Admin" || gigyaRole === "Regional Admin" || gigyaRole === "Internal") ? (
            <div className="profile">
              <Link to={`/Administration`}>
                <span>
                <i class="bi bi-person-fill"></i>
                </span>
              </Link>
            </div>):""}
            <div className="logout" onClick={logOutOnClick}>
              {" "}
              <span>
                <i className="bi bi-power"></i>
              </span>
            </div>
          </div>
        </Container>
        {breadcrumb.length > 1 && (
          <div className="bread-crumb-container">
            {<Breadcrumbs crumbs={breadcrumb} />}
          </div>
        )}
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    brandList: state.filter.brand,
    assetType: state.filter.assets,
    countrySettings: state.country.countryData,
    productsList: state.product.productsList,
    assetsList: state.asset.assetsList,
    RoleSettings: state.role.countryData,
    countryLocale: state.locale.countryLocale
  };
};

// function dummySkeletonHeader() {
//   return (
//     <>
//       <Col xs={1} className="brand">
//         <Skeleton height={30} width={72} />
//       </Col>
//       <Col xs={2} className="asset">
//         <Skeleton height={30} width={110} />
//       </Col>
//       <Col className="search" xs={{ span: 4, offset: 0 }}>
//         <Skeleton className="mt-4" height={50} />
//       </Col>
//     </>
//   );
// }

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    null
  )
)(Header);
