import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SearchFilter from "../SearchFilter/searchFilter.jsx";
//import CardComponent from "../Card/card.jsx";
import "./searchResult.scss";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  updateFilterRemParam,
  setFilterToggleData,
  updateFavProductsList,
  updateFavAssetsList,
  setFilterBrandName,
  setFilterAssetTypes,
  setFilterYear,
  setFilterFileTypes,
  updateAssets,
  updateRenderProductList,
  updateRenderAssetList,
  updateFilterBrandParam,
  updateFilterAssetTypeParam
} from "../../redux";
import Fuse from "fuse.js";
import qs from "qs";
import _ from "lodash";
import FilterLabel from "../FilterLabel/FilterLabel.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import MediaQuery from "react-responsive";
import Skeleton from "react-loading-skeleton";
import { ProgressLoader } from "../ProgressLoader/progressLoader";
import Search from "../Search/search";
import PaginatedItems from "../PaginatedItems/PaginatedItems"


const env = process.env.REACT_APP_ENVIRONMENT;
let ga_env = env === "PROD" ? "Production" : "Testing";

class SearchGrid extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.toggleButtonRef = React.createRef();
    this.containerRef = React.createRef();
    this.countryLocale= this.props.countryLocale;
    this.state = {
      is_visible: false,
      filterNames: [],
      sortButton: [
        {
          id: "sortAlphabet",
          value: "alphabet",
          name: t("Filter and Sort.alphabetical"),
          sortType: "sortAlphabet",
          selected: false
        },

        {
          id: "sortPublishDate",
          value: "publishDate",
          name: t("Filter and Sort.release_date"),
          sortType: "sortPublishDate",
          selected: false
        },

        {
          id: "sortFileSize",
          value: "fileSize",
          name: t("Filter and Sort.file_size"),
          sortType: "sortFileSize",
          selected: false
        }
      ],
      filterParam: {},
      sortParam: {},
      toggleData: [
        {
          id: "showProduct",
          value: "product",
          name: "Products",
          filterType: "toggleData"
        },
        {
          id: "showAsset",
          value: "asset",
          name: "Assets",
          filterType: "toggleData"
        }
      ],
      filterToggle: {},
      filteredDataLength: ""
    };
  }

  componentDidMount() {
    var scrollComponent = this;
    const appContainer = document.querySelector(".App");
    appContainer.addEventListener("scroll", function(e) {
      scrollComponent.toggleVisibility();
    });
    appContainer.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    this.handleURLParams();
  }

  componentDidUpdate(prevProps) {
    //const queryParams = this.props.location.search;
    //const queryObject = qs.parse(queryParams, { ignoreQueryPrefix: true });
    // const prevParams = prevProps.location.search;
    //const prevParamObject = qs.parse(prevParams, { ignoreQueryPrefix: true });
    if (!_.isEqual(prevProps, this.props)) {
      this.handleURLParams();
    }
  }

  normalValueShow =(item, particularAttribute, countryLocale)=>{
    return(
      typeof item?.[particularAttribute] === "string"
      ? item?.[particularAttribute]  : countryLocale &&  item?.[particularAttribute]?.[countryLocale] ?
      item?.[particularAttribute]?.[countryLocale]  : item?.[particularAttribute]?.['en-ZZ']
    )
  }

  handleURLParams = () => {
    const queryParams = this.props.location.search;
    const filterObject = qs.parse(queryParams, { ignoreQueryPrefix: true });
    let renderProductList = [],
      renderAssetList = [];

    if (Object.keys(filterObject).includes("showNewDigitalContent")) {
      renderProductList =
        this.props.renderProductList.length > 0
          ? this.props.renderProductList
          : [];
    }
    if (Object.keys(filterObject).includes("showNewDigitalContent")) {
      renderAssetList =
        this.props.renderAssetList.length > 0 ? this.props.renderAssetList : [];
    } else if (Object.keys(filterObject).length !== 0) {
      renderAssetList = [].concat(this.props.assetsList);
    }
    if (
      Object.keys(filterObject).includes("searchKey") &&
      filterObject.searchKey.length > 0
    ) {
      const productOptions =
        this.props.countrySettings.countryCode === "NL"
          ? {
            includeMatches: true,
            minMatchCharLength: 3,
            keys: [
              `brand.${this.props.countryLocale}`,
            `unitupc`,
            `ean.${this.props.countryLocale}`,
            `ptitle.${this.props.countryLocale}`,
            `ecomproddesc.${this.props.countryLocale}`,
              "keyword",
              "asset_info_print_keywords"
            ],
            threshold: 0.3
          }
          : {
            includeMatches: true,
            minMatchCharLength: 3,
            keys: [
              `brand.${this.props.countryLocale}`,
              `unitupc`,
              `pharmacentralnum.${this.props.countryLocale}`,
              `ptitle.${this.props.countryLocale}`,
              `ecomproddesc.${this.props.countryLocale}`,
              "keyword",
              "asset_info_print_keywords"
            ],
            threshold: 0.3
          };
      const assetOptions =
        this.props.countrySettings.countryCode === "NL"
          ? {
            includeMatches: true,
            minMatchCharLength: 3,
            keys: [
              "brand",
              "asset_type",
              "file_type",
              "title",
              "description",
              "ean",
              "keyword",
              "assetId",
              "dynamic_title"
            ],
            threshold: 0.3
          }
          : {
            includeMatches: true,
            minMatchCharLength: 3,
            keys: [
              "brand",
              "asset_type",
              "file_type",
              "title",
              "description",
              "pharmacentralnum",
              "keyword",
              "assetId",
              "dynamic_title"
            ],
            threshold: 0.3
          };
      var productFuse = "";
      var assetFuse = "";
      if (renderProductList.length === 0) {
        this.props.productsList.forEach(product => {
          if (Object.keys(product).includes("asset_info")) {
            let keySearch = product.asset_info;
            if (Object.keys(keySearch).includes("keywords")) {
              let productKeys = Array.isArray(keySearch.keywords)
                ? keySearch.keywords[0].split(",")
                : keySearch.keywords.split(",");
              productKeys.forEach(function(productKey) {
                productKey = productKey.trim();
              });
              product.keyword = productKeys;
            }
          }
          if (Object.keys(product).includes("asset_info_print")) {
            let keySearch = product.asset_info_print;
            if (Object.keys(keySearch).includes("keywords")) {
              let productKeys = Array.isArray(keySearch.keywords)
                ? keySearch.keywords[0].split(",")
                : keySearch.keywords.split(",");
              productKeys.forEach(function(productKey) {
                productKey = productKey.trim();
              });
              product.asset_info_print_keywords = productKeys;
            }
          }
        });
        productFuse = new Fuse(this.props.productsList, productOptions);
      } else {
        renderProductList.forEach(product => {
          if (Object.keys(product).includes("asset_info")) {
            let keySearch = product.asset_info;
            if (Object.keys(keySearch).includes("keywords")) {
              let productKeys = Array.isArray(keySearch.keywords)
                ? keySearch.keywords[0].split(",")
                : keySearch.keywords.split(",");
              productKeys.forEach(function(productKey) {
                productKey = productKey.trim();
              });
              product.keyword = productKeys;
            }
          }
          if (Object.keys(product).includes("asset_info_print")) {
            let keySearch = product.asset_info_print;
            if (Object.keys(keySearch).includes("keywords")) {
              let productKeys = Array.isArray(keySearch.keywords)
                ? keySearch.keywords[0].split(",")
                : keySearch.keywords.split(",");
              productKeys.forEach(function(productKey) {
                productKey = productKey.trim();
              });
              product.asset_info_print_keywords = productKeys;
            }
          }
        });
        productFuse = new Fuse(renderProductList, productOptions);
      }
      if (renderAssetList.length === 0) {
        this.props.assetsList.forEach(asset => {
          if (Object.keys(asset).includes("keywords")) {
            let assetKeys = Array.isArray(asset.keywords)
              ? asset.keywords[0].split(",")
              : asset.keywords.split(",");
            assetKeys.forEach(function(assetKey) {
              assetKey = assetKey.trim();
            });
            asset.keyword = assetKeys;
          }
        });
        assetFuse = new Fuse(this.props.assetsList, assetOptions);
      } else {
        renderAssetList.forEach(asset => {
          if (Object.keys(asset).includes("keywords")) {
            let assetKeys = Array.isArray(asset.keywords)
              ? asset.keywords[0].split(",")
              : asset.keywords.split(",");
            assetKeys.forEach(function(assetKey) {
              assetKey = assetKey.trim();
            });
            asset.keyword = assetKeys;
          }
          asset.dynamic_title = this.dynamicAssetTitle(asset);
        });
        assetFuse = new Fuse(renderAssetList, assetOptions);
      }
      const productResult = productFuse.search(filterObject.searchKey);
      const assetResult = assetFuse.search(filterObject.searchKey);
      var productArray = [];
      var assetArray = [];
      productResult.forEach(product => {
        productArray.push(product.item);
      });
      assetResult.forEach(asset => {
        assetArray.push(asset.item);
      });
      if (productArray.length > 0) {
        renderProductList = productArray;
      } else {
        renderProductList = [];
      }
      if (assetArray.length > 0) {
        renderAssetList = assetArray;
      } else {
        renderAssetList = [];
      }
    }
    if (
      Object.keys(filterObject).includes("searchKey") &&
      filterObject.searchKey.length === 0
    ) {
      renderProductList = this.props.productsList;
      renderAssetList = this.props.assetsList;
    }

    Object.keys(filterObject).forEach(key => {
      let upc = [];
      switch (key) {
        case "brand":
          renderProductList =
          Object.keys(filterObject).indexOf("assettype") === -1 &&
          Object.keys(filterObject).indexOf("fileType") === -1
            ? this.props.productsList.filter(product =>
                filterObject.brand.some(brand =>
                  this.normalValueShow(product,"brand",this.props.countryLocale).toLowerCase() === brand.toLowerCase()
                )
              )
            : [];
        renderAssetList = renderAssetList.filter(asset =>
          filterObject.brand.some(brand =>
            this.normalValueShow(asset,"brand",this.props.countryLocale).toLowerCase() === brand.toLowerCase()
          )
        );

          break;
        case "assettype":
          renderProductList = [];

          let temp = []
          for(let i =0; i < filterObject.assettype.length; i++){
           temp= temp.concat(renderAssetList.filter(asset => JSON.stringify(Object.values(asset.asset_type)).toLowerCase().includes(filterObject.assettype[i].toLowerCase())))    
          }
          renderAssetList =temp

        //   renderAssetList = renderAssetList.filter(asset => {
        //     let bool = filterObject.assettype.some(assettype =>
        //       asset.asset_type.some(asset_type =>
        //         Object.values(asset_type).includes(assettype)
        //       )
        //     );
        //     return bool;
        //   });
          renderAssetList.forEach(asset => upc.push(asset.upc));
         filterObject.assettype.filter((asset) => asset ===("Package Leaflet") ? renderProductList=[] 
         :
         renderProductList = this.props.productsList.filter(product => {
          return upc.some(val => this.normalValueShow(product,"unitupc",this.props.countryLocale) === val);
        }))
          

          break;
        case "fileType":
          renderAssetList = renderAssetList.filter(asset =>
            filterObject.fileType.includes(asset.file_type)
          );
          renderProductList = [];
          renderAssetList.forEach(asset => upc.push(asset.upc));
          renderProductList = this.props.productsList.filter(product => {
            return upc.some(val => this.normalValueShow(product,"unitupc",this.props.countryLocale) === val);
          });
          break;
        default:
          break;
      }
    });

    const uniqueAssetList = [...new Set(renderAssetList)];
    let assetList = uniqueAssetList.filter(
      asset => asset.asset_type[0].dcAssetType === "Package Leaflet" || !Object.keys(asset).includes("upc")
    );
    if (Object.keys(filterObject).includes("showNewDigitalContent")){
      this.props.updateRenderAssetList(uniqueAssetList);
    }
    else{
      this.props.updateRenderAssetList(assetList);
    }   
    this.props.updateRenderProductList(renderProductList);
    if (Object.keys(filterObject).length > 0) {
      Object.keys(filterObject).forEach(key => {
        switch (key) {
          case "brand":
            const brandList = [].concat(this.props.brandList);
            brandList.forEach(brand => {
              brand.itemChecked = filterObject[key].includes(brand.brandName)
                ? true
                : false;
            });
            this.props.setFilterBrandName(brandList);
            if (!Object.keys(filterObject).includes("assettype")) {
              const assettype = [].concat(this.props.assetType);
              assettype.forEach(asset => (asset.itemChecked = false));
              this.props.setFilterAssetTypes(assettype);
            }
            if (!Object.keys(filterObject).includes("fileType")) {
              const fileTypes = [].concat(this.props.fileTypes);
              fileTypes.forEach(fileType => (fileType.itemChecked = false));
              this.props.setFilterFileTypes(fileTypes);
            }

            break;
          case "assettype":
            const assettype = [].concat(this.props.assetType);
            assettype.forEach(asset => {
              asset.itemChecked = filterObject[key].includes(
                asset.asset_type.dcAssetType
              )
                ? true
                : false;
            });
            this.props.setFilterAssetTypes(assettype);
            if (!Object.keys(filterObject).includes("brand")) {
              const brandList = [].concat(this.props.brandList);
              brandList.forEach(brand => (brand.itemChecked = false));
              this.props.setFilterBrandName(brandList);
            }
            if (!Object.keys(filterObject).includes("fileType")) {
              const fileTypes = [].concat(this.props.fileTypes);
              fileTypes.forEach(fileType => (fileType.itemChecked = false));
              this.props.setFilterFileTypes(fileTypes);
            }
            break;
          case "fileType":
            const fileTypes = [].concat(this.props.fileTypes);
            fileTypes.forEach(fileType => {
              fileType.itemChecked = filterObject[key].includes(
                fileType.file_type
              )
                ? true
                : false;
            });
            this.props.setFilterFileTypes(fileTypes);
            if (!Object.keys(filterObject).includes("brand")) {
              const brandList = [].concat(this.props.brandList);
              brandList.forEach(brand => (brand.itemChecked = false));
              this.props.setFilterBrandName(brandList);
            }
            if (!Object.keys(filterObject).includes("assettype")) {
              const assettype = [].concat(this.props.assetType);
              assettype.forEach(asset => (asset.itemChecked = false));
              this.props.setFilterAssetTypes(assettype);
            }
            break;
          default:
            if (!Object.keys(filterObject).includes("brand")) {
              const brandList = [].concat(this.props.brandList);
              brandList.forEach(brand => (brand.itemChecked = false));
              this.props.setFilterBrandName(brandList);
            }
            if (!Object.keys(filterObject).includes("assettype")) {
              const assettype = [].concat(this.props.assetType);
              assettype.forEach(asset => (asset.itemChecked = false));
              this.props.setFilterAssetTypes(assettype);
            }
            if (!Object.keys(filterObject).includes("fileType")) {
              const fileTypes = [].concat(this.props.fileTypes);
              fileTypes.forEach(fileType => (fileType.itemChecked = false));
              this.props.setFilterFileTypes(fileTypes);
            }
            break;
        }
      });
    } else {
      const brandList = [].concat(this.props.brandList);
      brandList.forEach(brand => (brand.itemChecked = false));
      this.props.setFilterBrandName(brandList);
      const assettype = [].concat(this.props.assetType);
      assettype.forEach(asset => (asset.itemChecked = false));
      this.props.setFilterAssetTypes(assettype);
      const fileTypes = [].concat(this.props.fileTypes);
      fileTypes.forEach(fileType => (fileType.itemChecked = false));
      this.props.setFilterFileTypes(fileTypes);
    }
    const filterBrandParam = _.has(filterObject, "brand")
      ? _.pick(filterObject, ["brand"])
      : null;
    const filterAssetParam = _.has(filterObject, "assettype")
      ? _.pick(filterObject, ["assettype"])
      : null;
    const filterfileTypeParam = _.has(filterObject, "fileType")
      ? _.pick(filterObject, ["fileType"])
      : null;

    const filterRemParam = {};
    Object.assign(filterRemParam, filterfileTypeParam);
    this.props.updateFilterBrandParam(filterBrandParam);
    this.props.updateFilterAssetTypeParam(filterAssetParam);
    this.props.updateFilterRemParam(filterRemParam);
  };

  toggleVisibility() {
    if (this.containerRef.current) {
      var scrollTop = this.containerRef.current.getBoundingClientRect().top;
      if (scrollTop < -50) {
        this.setState({
          is_visible: true
        });
      } else {
        this.setState({
          is_visible: false
        });
      }
    }
  }

  scrollToTop() {
    const appContainer = document.querySelector(".App");
    appContainer.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  updateSortList(selectedButtonIndex, type) { 
    const buttons = this.state.sortButton   
    buttons.forEach((item, index) => {
     if((index.toString(10) === selectedButtonIndex)  && (item.selected === true)) {
      item.selected = false
      } 
      else if(index.toString(10) === selectedButtonIndex) {
        item.selected = true
      } else {
        item.selected = false
      }
    })
    
    this.setState({sortButton: buttons})
  }
  handleSortOnClick = event => {
    event.preventDefault()
    this.updateSortList(event.target.id, event.type)
    const paramValue = event.target.getAttribute("value");
    const paramType = event.target.getAttribute("sorttype");
    const paramObj = {};
    paramObj[paramType] = paramValue;
    const sortParam = this.state.sortParam;
    const key = Object.keys(sortParam);
    if (key.length > 0) {
      delete sortParam[key];
    }
    if (!(key.toString() === paramType)) {
      Object.assign(sortParam, paramObj);
    }
    this.setState({ sortParam });
  };

  filterList = (listToFilter, filter) => {
    let filteredList = [];
    if (Object.values(filter).length === 0) {
      filteredList = listToFilter;
    } else {
      filteredList = [].concat(listToFilter);
      for (let key in filter) {
        switch (key) {
          /*  case "fileType":
            filteredList = filteredList.filter(object => {
              if (object.type === "asset") {
                if (filter[key].includes(object["file_type"])) {
                  return true;
                }
                return false;
              }
              if (object.type === "product") {
                return false;
              }
              return false;
            });

            break; */

          case "publishedYear":
            filteredList = filteredList.filter(object => {
              if (object.type === "asset") {
                const year = new Date(object.campaign_year).getFullYear();
                if (filter[key].includes(year.toString())) {
                  return true;
                }
                return false;
              }
              if (object.type === "product") {
                const year = new Date(object.launchdate).getFullYear();
                if (filter[key].includes(year.toString())) {
                  return true;
                }
                return false;
              }
              return false;
            });
            break;
          default:
            break;
        }
      }
    }
    return filteredList;
  };

  dynamicAssetTitle = item => {
    const { t } = this.props;
    let asset_title = [];
    if (_.has(item, "brand")) {
      let assetBrand = this.normalValueShow(item,"brand",this.props.countryLocale);
      if (_.has(item, "sub_brand")) {
        asset_title.push(item.sub_brand[0]);
      } else {
        asset_title.push(assetBrand);
      }
    }
    if (_.has(item, "asset_type")) {
      asset_title.push(t(`Asset Type.${item.asset_type[0].dcAssetType}`));
    }
    if (_.has(item, "customer_explanation")) {
      asset_title.push(item.customer_explanation);
    }

    asset_title = asset_title.join(" ");

    return asset_title;
  };

  sortList = (listToSort, sortParam) => {
    const filter = sortParam;
    const param = Object.values(filter).toString();
    const sortList = [].concat(listToSort);
    let sortedList = [];

    switch (param) {
      case "alphabet":
        sortedList = sortList.sort((a, b) => {
          let result;
          if (a.type === "asset" && b.type === "asset") {
            let a_title = this.dynamicAssetTitle(a);
            let b_title = this.dynamicAssetTitle(b);
            result = a_title.localeCompare(b_title);
          }
          if (a.type === "product" && b.type === "product") {
            if (
              Object.keys(a).includes("ptitle") &&
              Object.keys(b).includes("ptitle")
            ) {
              result =this.normalValueShow(a,"ptitle",this.props.countryLocale).localeCompare(this.normalValueShow(b,"ptitle",this.props.countryLocale));
            }
          }
          return result;
        });

        break;
      case "publishDate":
        sortedList = sortList
          .sort(function(a, b) {
            let result;
            if (a.type === "asset" && b.type === "asset") {
              const dateA = Object.keys(a).some(key => key === "campaign_year")
                ? new Date(a.campaign_year).getFullYear()
                : "";
              const dateB = Object.keys(b).some(key => key === "campaign_year")
                ? new Date(b.campaign_year).getFullYear()
                : "";
              result = dateA - dateB;
            }
            if (a.type === "product" && b.type === "product") {
              const dateA = Object.keys(a).some(key => key === "launchdate")
                ? new Date(this.normalValueShow(a,"launchdate",this.props.countryLocale))
                : "";
              const dateB = Object.keys(a).some(key => key === "launchdate")
                ? new Date(this.normalValueShow(b,"launchdate",this.props.countryLocale))
                : "";
              result = dateA - dateB;
            }
            return result;
          })
          .reverse();
        break;
      case "fileSize":
        sortedList = sortList
          .sort((a, b) => {
            let result;
            if (a.type === "asset" && b.type === "asset") {
              let fileSizeA;
              let fileSizeB;
              if (Object.keys(a).some(key => key === "file_size")) {
                const size = a.file_size;
                let bytes;
                let unit;
                if (a.file_size.includes("MB")) {
                  unit = "MB";
                } else if (a.file_size.includes("KB")) {
                  unit = "KB";
                } else {
                  unit = "";
                }

                switch (unit) {
                  case "MB":
                    bytes = size.match(/\d/g);
                    bytes = bytes.join("");
                    bytes = parseInt(bytes, 10) * 1000 * 1000;
                    fileSizeA = bytes;
                    break;
                  case "KB":
                    bytes = size.match(/\d/g);
                    bytes = bytes.join("");
                    bytes = parseInt(bytes, 10) * 1000;
                    fileSizeA = bytes;
                    break;
                  default:
                    bytes = size;
                    bytes = parseInt(bytes, 10);
                    fileSizeA = bytes;
                    break;
                }
              } else {
                fileSizeA = 0;
              }
              if (Object.keys(b).some(key => key === "file_size")) {
                const size = b.file_size;
                let bytes;
                let unit;
                if (b.file_size.includes("MB")) {
                  unit = "MB";
                } else if (a.file_size.includes("KB")) {
                  unit = "KB";
                } else {
                  unit = "";
                }
                switch (unit) {
                  case "MB":
                    bytes = size.match(/\d/g);
                    bytes = bytes.join("");
                    bytes = parseInt(bytes, 10) * 1000 * 1000;
                    fileSizeB = bytes;
                    break;
                  case "KB":
                    bytes = size.match(/\d/g);
                    bytes = bytes.join("");
                    bytes = parseInt(bytes, 10) * 1000;
                    fileSizeB = bytes;
                    break;
                  default:
                    bytes = size;
                    bytes = parseInt(bytes, 10);
                    fileSizeB = bytes;
                    break;
                }
              } else {
                fileSizeB = 0;
              }
              result = fileSizeA - fileSizeB;
            }
            if (a.type === "product" && b.type === "product") {
              let fileSizeA;
              let fileSizeB;
              if (Object.keys(a).some(key => key === "asset_info")) {
                const size = Object.keys(a.asset_info).includes("file_size")
                  ? a.asset_info.file_size
                  : "0";
                let bytes;
                let unit;
                if (size.includes("MB")) {
                  unit = "MB";
                } else if (size.includes("KB")) {
                  unit = "KB";
                } else {
                  unit = "";
                }

                switch (unit) {
                  case "MB":
                    bytes = size.match(/\d/g);
                    bytes = bytes.join("");
                    bytes = parseInt(bytes, 10) * 1000 * 1000;
                    fileSizeA = bytes;
                    break;
                  case "KB":
                    bytes = size.match(/\d/g);
                    bytes = bytes.join("");
                    bytes = parseInt(bytes, 10) * 1000;
                    fileSizeA = bytes;
                    break;
                  default:
                    bytes = size;
                    bytes = parseInt(bytes, 10);
                    fileSizeA = bytes;
                    break;
                }
              } else if (
                Object.keys(a).some(key => key === "asset_info_print")
              ) {
                const size = Object.keys(a.asset_info_print).includes(
                  "file_size"
                )
                  ? a.asset_info_print.file_size
                  : "0";
                let bytes;
                let unit;
                if (size.includes("MB")) {
                  unit = "MB";
                } else if (size.includes("KB")) {
                  unit = "KB";
                } else {
                  unit = "";
                }

                switch (unit) {
                  case "MB":
                    bytes = size.match(/\d/g);
                    bytes = bytes.join("");
                    bytes = parseInt(bytes, 10) * 1000 * 1000;
                    fileSizeA = bytes;
                    break;
                  case "KB":
                    bytes = size.match(/\d/g);
                    bytes = bytes.join("");
                    bytes = parseInt(bytes, 10) * 1000;
                    fileSizeA = bytes;
                    break;
                  default:
                    bytes = size;
                    bytes = parseInt(bytes, 10);
                    fileSizeA = bytes;
                    break;
                }
              } else {
                fileSizeA = 0;
              }
              if (Object.keys(b).some(key => key === "asset_info")) {
                const size = Object.keys(b.asset_info).includes("file_size")
                  ? b.asset_info.file_size
                  : "0";
                let bytes;
                let unit;
                if (size.includes("MB")) {
                  unit = "MB";
                } else if (size.includes("KB")) {
                  unit = "KB";
                } else {
                  unit = "";
                }
                switch (unit) {
                  case "MB":
                    bytes = size.match(/\d/g);
                    bytes = bytes.join("");
                    bytes = parseInt(bytes, 10) * 1000 * 1000;
                    fileSizeB = bytes;
                    break;
                  case "KB":
                    bytes = size.match(/\d/g);
                    bytes = bytes.join("");
                    bytes = parseInt(bytes, 10) * 1000;
                    fileSizeB = bytes;
                    break;
                  default:
                    bytes = size;
                    bytes = parseInt(bytes, 10);
                    fileSizeB = bytes;
                    break;
                }
              } else if (
                Object.keys(b).some(key => key === "asset_info_print")
              ) {
                const size = Object.keys(b.asset_info_print).includes(
                  "file_size"
                )
                  ? b.asset_info_print.file_size
                  : "0";
                let bytes;
                let unit;
                if (size.includes("MB")) {
                  unit = "MB";
                } else if (size.includes("KB")) {
                  unit = "KB";
                } else {
                  unit = "";
                }
                switch (unit) {
                  case "MB":
                    bytes = size.match(/\d/g);
                    bytes = bytes.join("");
                    bytes = parseInt(bytes, 10) * 1000 * 1000;
                    fileSizeB = bytes;
                    break;
                  case "KB":
                    bytes = size.match(/\d/g);
                    bytes = bytes.join("");
                    bytes = parseInt(bytes, 10) * 1000;
                    fileSizeB = bytes;
                    break;
                  default:
                    bytes = size;
                    bytes = parseInt(bytes, 10);
                    fileSizeB = bytes;
                    break;
                }
              } else {
                fileSizeB = 0;
              }
              result = fileSizeA - fileSizeB;
            }
            return result;
          })
          .reverse();
        break;
      default:
        sortedList = listToSort;
        break;
    }

    return sortedList;
  };

  createRenderList = (objectList, filterParam, sortParam) => {
    let dataList = [];
    if (objectList.length > 0) {
      dataList = this.filterList(objectList, filterParam);
      dataList = this.sortList(dataList, sortParam);
    }
    return dataList;
  };

  handleToggleChange = event => {
    const refButton = this.toggleButtonRef.current;
    const paramValue = event.target.value;
    const paramType = event.currentTarget.getAttribute("filtertype");
    const paramObj = {};
    let filterToggle = { ...this.state.filterToggle };
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Filter",
      eventAction: paramValue,
      eventLabel: "Entity Type",
      environment: ga_env
    });

    refButton.childNodes.forEach(button => {
      if (button.firstElementChild.value === paramValue) {
        button.classList.contains("active")
          ? button.classList.remove("active")
          : button.classList.add("active");
      } else {
        button.classList.contains("active") &&
          button.classList.remove("active");
        button.firstElementChild.getAttribute("checked") === "true" &&
          button.firstElementChild.setAttribute("checked", "false");
      }
    });

    if (event.target.getAttribute("checked") !== "true") {
      event.target.setAttribute("checked", "true");
      paramObj[paramType] = [];
      paramObj[paramType].push(paramValue);

      /* if (filterToggle.hasOwnProperty(paramType)) {
        if (!filterToggle[paramType].includes(paramValue)) {
          filterToggle[paramType].push(paramValue);
        }
      } else {
        Object.assign(filterToggle, paramObj);
      } */
      Object.assign(filterToggle, paramObj);
      this.setState({ filterToggle });
      this.props.history.replace(`/search-page/`);
    } else {
      event.target.setAttribute("checked", "false");
      if (
        filterToggle !== undefined &&
        Object.keys(filterToggle).includes(paramType)
      ) {
        const len = filterToggle[paramType].length;
        const index = filterToggle[paramType].indexOf(paramValue);
        switch (index) {
          case 0:
            filterToggle[paramType].shift();
            if (filterToggle[paramType].length === 0) {
              delete filterToggle[paramType];
            }
            this.setState({ filterToggle });
            this.props.history.replace(`/search-page/`);
            break;
          case len - 1:
            filterToggle[paramType].pop();
            break;
          default:
            filterToggle[paramType].splice(index, 1);
            break;
        }
      }
    }
    const toggleData = [].concat(this.state.toggleData);
    toggleData.forEach(view => {
      for (let key in filterToggle) {
        if (filterToggle[key].includes(view.name)) {
          view.itemChecked = true;
        } else {
          view.itemChecked = false;
        }
      }
    });

    this.setState({ toggleData });
  };

  dummySkeletonLoader = () => {
    return (
      <>
        <div className="sortRow">
          <div className="skeletonFilter">
            <Skeleton height={38} width={256} />
          </div>
          <div className="skeletonFilter">
            <Skeleton height={38} width={256} />
          </div>
          <div className="skeletonFilter">
            <Skeleton height={38} width={256} />
          </div>
          <div className="skeletonFilter">
            <Skeleton height={38} width={256} />
          </div>
        </div>
        <div className="row serp-mini-headline">
          <div className="col-4">
            <Skeleton height={50} width={356} />
          </div>
          <div className="col-4">
            <Skeleton height={50} width={356} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-3">
                <Skeleton height={440} width={240} />
              </div>
              <div className="col-3">
                <Skeleton height={440} width={240} />
              </div>
              <div className="col-3">
                <Skeleton height={440} width={240} />
              </div>
              <div className="col-3">
                <Skeleton height={440} width={240} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { t } = this.props;
    const is_visible = this.state.is_visible;
    let products = [].concat(this.props.renderProductList);
    let assets = [].concat(this.props.renderAssetList);
    const filterParam = this.props.filterRem;
    const filterToggle = this.state.filterToggle;
    const sortParam = this.state.sortParam;
    let productList = [];
    let assetList = [];
    let toggleView;
    let toggleParams = [];
    const { filterBrand, filterAsset, filterRem } = this.props;
    filterToggle.hasOwnProperty("toggleData")
      ? (toggleView = filterToggle["toggleData"])
      : (toggleView = []);
    if (assets.length === 0) {
      /*  if (filterParam.hasOwnProperty("fileType")) {
        if (filterParam["fileType"].length > 0) {
          assets = [].concat(this.props.assetsList);
        }
      } */
      if (filterParam.hasOwnProperty("publishedYear")) {
        if (filterParam["publishedYear"].length > 0) {
          assets = [].concat(this.props.assetsList);
        }
      }
    }
    if (products.length === 0) {
      if (filterParam.hasOwnProperty("publishedYear")) {
        if (filterParam["publishedYear"].length > 0) {
          products = [].concat(this.props.productsList);
        }
      }
    }

    productList = this.createRenderList(products, filterParam, sortParam);
    assetList = this.createRenderList(assets, filterParam, sortParam);
    if (Object.keys(toggleView).length > 0) {
      toggleParams = Object.values(toggleView);
    }

    let displayProductList =
      toggleParams.length === 0 || toggleParams.includes("Products")
        ? productList
        : [];
    let displayAssetList =
      toggleParams.length === 0 || toggleParams.includes("Assets")
        ? assetList
        : [];
   // const totalNumOfProductsAssets = displayProductList.length + displayAssetList.length

    const sendingProps = (sendingProps) => {
      this.setState({ filteredDataLength: sendingProps })
    }
    return (
      <React.Fragment>
        <div className="container serp" ref={this.containerRef}>
          <div className="search-headline">
            <span>{t("BreadCrumbs.searchPage")}</span>
          </div>
          <ProgressLoader
            area="initial-loading"
            skeleton={this.dummySkeletonLoader()}
            render={
              <>
                <MediaQuery minWidth={992}>
               
            
                  <div className="row">
                    <div className="search-filter-options col-3">
                    <FilterLabel
                    brand={filterBrand}
                    assetType={filterAsset}
                    fileType={filterRem}
                  />
                      <div className="left-hand-menu">
                      {/* <div className="heading">{t("Filter and Sort.filter_Options")}</div> */}
                        <SearchFilter />
                      </div>
                    </div>
                    <div className="search-result-display col-9" >
                      <div className="row">
                        <div className="col-8">
                          <Search />
                        </div>
                        <div className="col-4">
                          <div className="sort">
                            <div className="sort-button">
                              <div className="dropdown">
                                <button
                                  className="btn btn-secondary dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span>{t("Miscellaneous.sort-button")}</span>
                                </button>
                                <FontAwesomeIcon
                                  className="funnel-icon"
                                  icon={faFilter}
                                />
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  {this.state.sortButton.map((button, index) => {
                                    return (
                                      <li key={button.id}>
                                        <button
                                          id= {index}
                                          className= {button.selected ? "dropdown-item sort-dropdown" : "dropdown-item"}
                                          value={button.value}
                                          sorttype={button.sortType}
                                          onClick={event =>
                                            this.handleSortOnClick(event)
                                          }
                                        >
                                          {button.name}
                                        </button>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row serp-mini-headline justify-content-center">
                        <MediaQuery minWidth={992}>
                          <div className="col-4">
                            <div className="result-count">
                              <span>

                             
                                {/* {totalNumOfProductsAssets > this.state.lastPageIndex ? this.state.lastPageIndex : totalNumOfProductsAssets }
                                  {" "} {t("SearchPage.results")}  {" "}
                                
                                Out Of{" "} */}
                                {/* {displayProductList.length +
                                  displayAssetList.length} */}
                                  {this.state.filteredDataLength}
                                  {" "}
                                {t("SearchPage.results")}
                              </span>
                            </div>
                          </div>
                        </MediaQuery>
                      </div>
                      <MediaQuery minWidth={1200}>

                        <div className="row">
                          {
                            ((displayProductList?.plcstatus?.['en-ZZ']?.toLowerCase().includes("ready for publication").length )=== 0 && displayAssetList.length === 0) ? null :
                           // console.log("displayAssetList",displayAssetList,displayProductList)
                           
                              <PaginatedItems itemsPerPage={12}
                              classValue={"col-4"}                            
                                displayProducts={displayProductList}
                                displayAssets={displayAssetList}
                                sendingProps={sendingProps}
                              />
                              
                          }
                        </div>

                      </MediaQuery>
                      <MediaQuery minWidth={992} maxWidth={1199}>
                        <div className="row">
                        {
                            ((displayProductList?.plcstatus?.['en-ZZ']?.toLowerCase().includes("ready for publication").length) === 0 && displayAssetList.length === 0) ? null :


                              <PaginatedItems itemsPerPage={12}
                              classValue={"col-4"}
                                displayProducts={displayProductList}
                                displayAssets={displayAssetList}
                                sendingProps={sendingProps}
                              />
                          }
                        </div>
                      </MediaQuery>
                    </div>
                  </div>
                </MediaQuery>
                <MediaQuery maxWidth={991}>
                  <div className="row justify-content-center">
                    <Search />
                  </div>
                  <div className="mobile-container">
                    <FilterLabel
                      brand={filterBrand}
                      assetType={filterAsset}
                      fileType={filterRem}
                    />
                    {/*   <div className="toggle-row">
                      <div className="togglebutton">
                        <div
                          className="btn-group btn-group-toggle"
                          data-toggle="buttons"
                          ref={this.toggleButtonRef}
                        >
                          {this.state.toggleData.map((item, index) => {
                            return (
                              <label className="btn btn-secondary">
                                <input
                                  type="checkbox"
                                  name="options"
                                  id={`option${index}`}
                                  onChange={this.handleToggleChange}
                                  filtertype="toggleData"
                                  value={item.name}
                                  checked
                                ></input>
                                {t(item.name)}
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="search-filter">
                        <div className="filter-row">
                          <SearchFilter />
                        </div>
                        <div className="sort">
                          <div className="sort-button">
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <span>{t("Miscellaneous.sort-button")}</span>
                              </button>
                              <FontAwesomeIcon
                                className="funnel-icon"
                                icon={faFilter}
                              />
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                {this.state.sortButton.map(button => {
                                  return (
                                    <li key={button.id}>
                                      <button
                                        className="dropdown-item"
                                        value={button.value}
                                        sorttype={button.sortType}
                                        onClick={event =>
                                          this.handleSortOnClick(event)
                                        }
                                      >
                                        {button.name}
                                      </button>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row serp-mini-headline justify-content-center">
                        <div className="col-6">
                          <div className="result-count">
                            <span>
                            {/* {totalNumOfProductsAssets > this.state.lastPageIndex ? this.state.lastPageIndex : totalNumOfProductsAssets }
                                  {" "} {t("SearchPage.results")}  {" "}
                                
                                Out Of{" "} */}
                                {/* {displayProductList.length +
                                  displayAssetList.length} */}
                                  {this.state.filteredDataLength}
                                  {" "}
                                {t("SearchPage.results")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <MediaQuery minWidth={481} maxWidth={991}>
                        <div className="row card-section justify-content-center">
                        {
                            ((displayProductList?.plcstatus?.['en-ZZ']?.toLowerCase().includes("ready for publication").length )=== 0 && displayAssetList.length === 0) ? null :


                              <PaginatedItems itemsPerPage={12}
                              classValue={"col-10"}
                                displayProducts={displayProductList}
                                displayAssets={displayAssetList}
                                sendingProps={sendingProps}
                              />
                          }
                        </div>
                      </MediaQuery>
                      <MediaQuery minWidth={376} maxWidth={480}>
                        <div className="row card-section justify-content-center">
                        {
                            ((displayProductList?.plcstatus?.['en-ZZ']?.toLowerCase().includes("ready for publication").length) === 0 && displayAssetList.length === 0) ? null :


                              <PaginatedItems itemsPerPage={12}
                              classValue={"col-10"}
                                displayProducts={displayProductList}
                                displayAssets={displayAssetList}
                                sendingProps={sendingProps}
                              />
                          }
                        </div>
                      </MediaQuery>
                      <MediaQuery maxWidth={375}>
                        <div className="row card-section justify-content-center">
                        {
                            ((displayProductList?.plcstatus?.['en-ZZ']?.toLowerCase().includes("ready for publication").length) === 0 && displayAssetList.length === 0) ? null :


                              <PaginatedItems itemsPerPage={12}
                              classValue={"col-10"}
                                displayProducts={displayProductList}
                                displayAssets={displayAssetList}
                                sendingProps={sendingProps}
                              />
                          }
                        </div>
                      </MediaQuery>
                    </div>
                  </div>
                </MediaQuery>
                {is_visible && (
                  <button id="upBtn" onClick={() => this.scrollToTop()}>
                    <FontAwesomeIcon className="up-icon" icon={faAngleUp} />
                    Top
                  </button>
                )}
              </>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    productsList: state.product.productsList,
    renderProductList: state.product.renderProductList,
    assetsList: state.asset.assetsList,
    brandList: state.filter.brand,
    assetType: state.filter.assets,
    publicationYear: state.filter.publicationYear,
    fileTypes: state.filter.fileTypes,
    renderAssetList: state.asset.renderAssetList,
    filterRem: state.filter.filterRem,
    toggleData: state.filter.toggleData,
    productLoading: state.product.loading,
    assetLoading: state.asset.loading,
    favAssetsList: state.asset.favAssetsList,
    favProductsList: state.product.favProductsList,
    filterBrand: state.filter.filterBrand,
    filterAsset: state.filter.filterAsset,
    countrySettings: state.country.countryData,
    countryLocale: state.locale.countryLocale
  };
};
const SearchResult = withTranslation("common")(SearchGrid);
const mapDispatchToProps = dispatch => {
  return {
    updateFilterRemParam: ownProps => dispatch(updateFilterRemParam(ownProps)),
    setFilterToggleData: ownProps => dispatch(setFilterToggleData(ownProps)),
    updateFavProductsList: ownProps =>
      dispatch(updateFavProductsList(ownProps)),
    updateFavAssetsList: ownProps => dispatch(updateFavAssetsList(ownProps)),
    setFilterBrandName: ownProps => dispatch(setFilterBrandName(ownProps)),
    setFilterAssetTypes: ownProps => dispatch(setFilterAssetTypes(ownProps)),
    setFilterYear: ownProps => dispatch(setFilterYear(ownProps)),
    setFilterFileTypes: ownProps => dispatch(setFilterFileTypes(ownProps)),
    updateAssets: ownProps => dispatch(updateAssets(ownProps)),
    updateRenderProductList: ownProps =>
      dispatch(updateRenderProductList(ownProps)),
    updateRenderAssetList: ownProps =>
      dispatch(updateRenderAssetList(ownProps)),
    updateFilterBrandParam: ownProps =>
      dispatch(updateFilterBrandParam(ownProps)),
    updateFilterAssetTypeParam: ownProps =>
      dispatch(updateFilterAssetTypeParam(ownProps))
  };
};
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SearchResult);